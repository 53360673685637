<div class="scope-snapshot-selector-rows-for-scope" *ngFor="let scopeDifference of descendantScopeDifferences">
	<div
		class="scope-snapshot-selector-row descendant-row"
		[ngClass]="{
			'first-inheritance-row': isFirstInheritanceRow(descendantScopeSnapshot),
			'last-inheritance-row': isLastInheritanceRow(descendantScopeSnapshot),
			'first-snapshot-within-scope': snapshotIndexWithinScope === 0,
			'last-snapshot-within-scope': snapshotIndexWithinScope === scopeDifference.descendantScopeSnapshots.length - 1
		}"
		#row
		*ngFor="let descendantScopeSnapshot of scopeDifference.descendantScopeSnapshots; let snapshotIndexWithinScope = index">

		<ng-container *ngIf="row as rowHTMLDivElement">
			<div
				class="state-transition-indicators"
				[ngClass]="{
					'initial-state-hidden': !isInheritanceRowChanged(descendantScopeSnapshot)
				}"
				[autoHide]="true"
				[pTooltip]="pTooltipForCurrentRowState(descendantScopeSnapshot)"
				tooltipPosition="bottom"
				[tooltipOptions]="{
					tooltipStyleClass: 'width-wider'
				}"
				>
				<ng-container>
					<div class="inheritance-row-state-indicator initial-state">
						<div class="text-label-wrapper" *ngIf="inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot, 'initial') as inheritance">
							<div class="text-label version-label hidden-spacer">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
							<div class="text-label version-label removed-from-document-flow">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
						</div>
						<!-- <div class="ficon ficon-AcceptMedium" *ngIf="inheritanceIfInheritingFromJustTargetScopeSnapshot(descendantScopeSnapshot, 'initial')"></div> -->
						<div class="ficon ficon-Remove" *ngIf="isInheritanceRowNonDescendant(descendantScopeSnapshot, 'initial')"></div>
						<div class="ficon ficon-BranchFork flip-vertically" *ngIf="isInheritanceRowDirectDescendant(descendantScopeSnapshot, 'initial')"></div>
						<div class="ficon ficon-SplitObject" *ngIf="isInheritanceRowIndirectDescendant(descendantScopeSnapshot, 'initial')"></div>
					</div>

					<div class="transformation-icon-wrapper">
						<div class="ficon ficon-SortDown transformation-icon"></div>
					</div>
				</ng-container>

				<div class="inheritance-row-state-indicator current-state" [ngClass]="{
					'conflicting-inheritances-state': isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot)
				}">
					<div class="text-label-wrapper" *ngFor="let inheritance of descendantScopeSnapshot.validatedInheritances">
						<div class="text-label version-label hidden-spacer">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
						<div class="text-label version-label removed-from-document-flow">{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}</div>
					</div>
					<!-- <ng-container class="ficon ficon-AcceptMedium" *ngIf="inheritanceIfInheritingFromJustTargetScopeSnapshot(descendantScopeSnapshot, 'current')"></ng-container> -->
					<div class="icon-wrapper" *ngIf="isInheritanceRowNonDescendant(descendantScopeSnapshot, 'current')">
						<div class="ficon ficon-Remove hidden-spacer"></div>
						<div class="ficon ficon-Remove removed-from-document-flow"></div>
					</div>
					<div class="icon-wrapper" *ngIf="isInheritanceRowDirectDescendant(descendantScopeSnapshot, 'current')">
						<div
							class="ficon hidden-spacer"
							[ngClass]="{
								'ficon-Childof': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId === targetScopeSnapshot.id,
								'ficon-BranchFork flip-vertically': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId !== targetScopeSnapshot.id
							}"></div>
						<div
							class="ficon removed-from-document-flow"
							[ngClass]="{
								'ficon-Childof': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId === targetScopeSnapshot.id,
								'ficon-BranchFork flip-vertically': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId !== targetScopeSnapshot.id
							}"></div>
					</div>
					<div class="icon-wrapper" *ngIf="isInheritanceRowIndirectDescendant(descendantScopeSnapshot, 'current')">
						<div class="ficon ficon-SplitObject hidden-spacer"></div>
						<div class="ficon ficon-SplitObject removed-from-document-flow"></div>
					</div>
					<div class="icon-wrapper" *ngIf="isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot)">
						<div class="ficon ficon-Important hidden-spacer"></div>
						<div class="ficon ficon-Important removed-from-document-flow"></div>
					</div>
				</div>
			</div>
			<app-scope-snapshot-selector
				[state]="{
					initialScopeSnapshot: component.primitiveToStaticScopeSnapshot(descendantScopeSnapshot, scopeDifference.descendantScope),
					actionsWhitelist: [],
					staticity: ssStaticity.STATIC_SCOPE_WITH_STATIC_VERSION,
					scopeVariables: scopeVariables
				}"
				[styles-for-spanning-across-parent-grid]="{
					'first-dropdown': {
						'grid-column-start': gridColumnStartOfScopeSnapshotSelector
					}
				}"
				[draw-snapshot-to-scope-connectors]="{
					areMoreSnapshotsAbove: snapshotIndexWithinScope > 0,
					areMoreSnapshotsBelow: snapshotIndexWithinScope < scopeDifference.descendantScopeSnapshots.length - 1,
					distanceFromSnapshotToMidlinePx: 40
				}"></app-scope-snapshot-selector>
			<div class="inheritance-row-actions first" [ngClass]="{
				'always-visible': isInheritanceRowChanged(descendantScopeSnapshot)
			}">
				<!-- <div
					class="action-link"
					(click)="viewInheritancesOf(descendantScopeSnapshot)">

				</div> -->
				<!-- TODO Also check to ensure its not conflicting inheritances-->
				<div
					class="custom-select enabled"
					[ngClass]="{
						'modified': isInheritanceRowChanged(descendantScopeSnapshot)
					}"
					*ngIf="!isInheritanceRowIndirectDescendant(descendantScopeSnapshot, 'current') && !isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot)">
					<div class="primary-label no-dirty-indicator">
						Inherit from
					</div>
					<div class="secondary-label" *ngIf="descendantScopeSnapshot.validatedInheritances.length <= 1">
						<ng-container *ngIf="isInheritanceRowChanged(descendantScopeSnapshot)">
							<div class="ficon"
								[ngClass]="{
									'ficon-Remove': descendantScopeSnapshot.savedInheritance === null,
									'ficon-SplitObject': descendantScopeSnapshot.savedInheritance && descendantScopeSnapshot.savedInheritance.isDirectInheritance === false,
									'ficon-Childof': descendantScopeSnapshot.savedInheritance?.isDirectInheritance && descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId === targetScopeSnapshot.id,
									'ficon-BranchFork flip-vertically': descendantScopeSnapshot.savedInheritance?.isDirectInheritance && descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId !== targetScopeSnapshot.id
								}"
							></div>
							<span class="show-as-older-state">
								<ng-container *ngIf="!descendantScopeSnapshot.savedInheritance">None</ng-container>
								<ng-container *ngIf="descendantScopeSnapshot.savedInheritance">{{versionLabel(descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshot.scopeVersion)}}</ng-container>
							</span>
							<div class="ficon ficon-SortDown transformation-icon"></div>
						</ng-container>
						<ng-container *ngIf="descendantScopeSnapshot.validatedInheritances.length === 0"><div class="ficon ficon-Remove"></div> None</ng-container>
						<ng-container *ngIf="descendantScopeSnapshot.validatedInheritances[0] as inheritance">
							<div class="ficon"
								[ngClass]="{
									'ficon-Childof': inheritance.inheritedScopeSnapshot.id === targetScopeSnapshot.id,
									'ficon-BranchFork flip-vertically': inheritance.inheritedScopeSnapshot.id !== targetScopeSnapshot.id
								}"
							></div> {{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}
						</ng-container>
					</div>
					<div class="dropdown-wrapper">
						<div class="dropdown">
							<div
								class="dropdown-option none-option"
								[ngClass]="{
									'initially-selected': descendantScopeSnapshot.savedInheritance === null,
									'newly-selected': descendantScopeSnapshot.validatedInheritances.length === 0
								}"
								(click)="setInheritanceOfDescendantToSnapshotOfTargetScope(descendantScopeSnapshot, null)">
								<div class="ficon ficon-Remove"></div>
								None
							</div>
							<div class="divider-line-and-margin"></div>
							<div
								class="dropdown-option"
								[ngClass]="{
									'initially-selected': descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshotId === inheritFromSnapshot.id,
									'newly-selected': descendantScopeSnapshot.validatedInheritances[0]?.inheritedScopeSnapshotId === inheritFromSnapshot.id
								}"
								(click)="setInheritanceOfDescendantToSnapshotOfTargetScope(descendantScopeSnapshot, inheritFromSnapshot.id)"
								*ngFor="let inheritFromSnapshot of inheritanceDropdownOptionsForRow(descendantScopeSnapshot)">
								<div
									class="ficon"
									[ngClass]="{
										'ficon-Childof': inheritFromSnapshot.id === targetScopeSnapshot.id,
										'ficon-BranchFork flip-vertically': inheritFromSnapshot.id !== targetScopeSnapshot.id
									}"></div>
								<div class="version-label">{{versionLabel(inheritFromSnapshot.scopeVersion)}}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="custom-select modified error spaced-secondary-labels" *ngIf="descendantScopeSnapshot.validatedInheritances.length > 1">
					<div class="primary-label no-dirty-indicator">
						Conflicting Inheritances:
					</div>
					<div class="secondary-label" *ngFor="let inheritance of descendantScopeSnapshot.validatedInheritances">
						{{versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)}}
					</div>
				</div>
			</div>
			<div class="inheritance-row-actions third">
				<div
					class="action-link"
					(click)="viewInheritancesOf(descendantScopeSnapshot)">
					<div class="ficon ficon-SplitObject"></div> Modify All Inheritances of {{versionLabel(descendantScopeSnapshot.scopeVersion)}}
				</div>
			</div>
		</ng-container>
	</div>
</div>
