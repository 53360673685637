<ng-container *ngIf="this.inheritancesSection">
	<div class="top-bar" *ngIf="component.inheritancesSectionStateTypes.MODIFY === inheritancesSection.stateType">
		<div class="title-and-description" *ngIf="component.inheritancesSectionStateTypes.MODIFY === this.inheritancesSection.stateType">
			<div class="subsection-smallcaps-title">{{requireStaticInheritances().length === 0 ? 'Set' : 'Modify'}} Inheritances of {{friendlyVersionLabel(requireTargetScopeSnapshot().scopeVersion)}}</div>
			<div class="section-description">
				To {{requireStaticInheritances().length === 0 ? "set" : "modify" }} this scope snapshot's inheritances as quickly as possible, select its new direct parents starting from the bottom, then work your way up until all eligible scopes are inherited.
			</div>
		</div>
		<div class="section-actions">
			<div
				class="action-link no-underline"
				[pTooltip]="'Discard ancestor modifications of ' + friendlyVersionLabel(requireTargetScopeSnapshot().scopeVersion)"
				[autoHide]="true"
				tooltipPosition="left"
				[tooltipOptions]="{
					tooltipStyleClass: 'width-max-content',
				}"
				(click)="discardAncestorModifications()">
				<div class="ficon ficon-Clear"></div>
			</div>
		</div>
	</div>

	<div class="inheritances-section">
		<!-- <div class="disabler-overlay" *ngIf="[inheritancesSectionStateTypes.LOADING_MODIFICATIONS, inheritancesSectionStateTypes.RELOADING_MODIFICATIONS, inheritancesSectionStateTypes.SAVING, inheritancesSectionStateTypes.SAVED].includes(inheritancesSection.state)"></div> -->
		<div
			class="current-inheritances"
			[ngStyle]="{
				'grid-template-columns': '[' + component.gridColumnStartOfScopeSnapshotSelector + '] repeat(' + (requireScopeVariables().length) + ', auto)'
			}"
			*ngIf="component.inheritancesSectionStateTypes.VIEW === inheritancesSection.stateType"
			>
			<div class="scope-snapshot-selector-row" *ngFor="let connection of requireStaticInheritances()">
				<app-scope-snapshot-selector
					[state]="{initialScopeSnapshot: connection.inheritedScopeSnapshot, actionsWhitelist: [], staticity: inheritedScopeSnapshotStaticity, scopeVariables: requireScopeVariables()}"
					[styles-for-spanning-across-parent-grid]="{
						'first-dropdown': {
							'grid-column-start': component.gridColumnStartOfScopeSnapshotSelector
						}
					}"
				>
				</app-scope-snapshot-selector>
				<div class="actions">
					<a [routerLink]="['/scope-snapshot/'+connection.inheritedScopeSnapshot.id]" class="action-link">Go to Scope Snapshot<div class="ficon icon-on-right ficon-Forward"></div></a>
				</div>
			</div>
			<div class="no-inheritances-notice" *ngIf="requireStaticInheritances().length === 0">
				This scope snapshot currently has no inheritances. You can modify its inheritances if desired.
			</div>
		</div>
		<!-- <div class="loading-modifiable-inheritances" *ngIf="inheritancesSectionStateTypes.LOADING_MODIFICATIONS === inheritancesSection.state">
			<p-skeleton height="34px" styleClass="mb-2"></p-skeleton>

			<div class="actions">
				<div class="action-link" (click)="null"><div class="ficon ficon-Cancel"></div> Cancel</div>
			</div>
		</div> -->
		<div class="modifiable-inheritances-and-errors" *ngIf="component.inheritancesSectionStateTypes.MODIFY === inheritancesSection.stateType">
			<div
				class="modifiable-inheritances"
				[ngStyle]="{
					'grid-template-columns': '[start] max-content [state-transition-arrow] max-content [current-state] max-content [inheritance-diagram] max-content [' + component.gridColumnStartOfScopeSnapshotSelector + '] repeat(' + (requireScopeVariables().length + 1) + ', max-content) [actions1] max-content [actions2] max-content [actions3] max-content [empty-gap] auto [end]',
					'grid-template-rows': '[start] repeat(' + (1 + inheritancesSection.inheritanceRowsMap.size) + ', auto) [divider] auto [base-scope-snapshot] auto [descendant-inheritances-start] auto [end]'
				}"
				[ngClass]="{
					'is-showing-descendants': isShowingDescendantScopeSnapshots()
				}"
				*ngIf="component.inheritanceDiagramConstants as diagramConsts"
			>
				<!-- rowIndex is 0-based. -->
				<ng-template
					#inheritanceDiagram
					let-strokeWidth="strokeWidth"
					let-strokeColor="strokeColor"
					let-strokeDashArray="strokeDashArray"
					let-pointToIndices="pointToIndices"
					let-diagramHeight="diagramHeight"
					let-rowIndex="rowIndex"
					>
					<svg class="inheritance-diagram" width="100%" [attr.height]="diagramHeight">
						<ng-container *ngIf="pointToIndices.length > 0">
							<!-- Vertical line from the highest direct parent down to the child. -->
							<line stroke-linecap="square"
								[attr.stroke-dasharray]="strokeDashArray"
								[attr.x1]="diagramConsts.mainVerticalLineX + '%'"
								[attr.x2]="diagramConsts.mainVerticalLineX + '%'"
								[attr.y1]="component.inheritanceDiagramMiddleOfRowY(Math.min.apply(Math, pointToIndices))"
								[attr.y2]="component.inheritanceDiagramMiddleOfRowY(rowIndex, rowIndex === inheritancesSection.inheritanceRowsMap.size)"
								[attr.stroke-width]="strokeWidth"
								[attr.stroke]="strokeColor" />

							<ng-container *ngFor="let ancestorIndex of pointToIndices">
								<!-- Horizontal line pointing to a direct parent. -->
								<line stroke-linecap="square"
									[attr.stroke-dasharray]="strokeDashArray"
									[attr.x1]="(diagramConsts.leftMarginForParentTriangle + diagramConsts.triangleWidth)"
									[attr.y1]="component.inheritanceDiagramMiddleOfRowY(ancestorIndex)"
									[attr.x2]="diagramConsts.mainVerticalLineX + '%'"
									[attr.y2]="component.inheritanceDiagramMiddleOfRowY(ancestorIndex)"
									[attr.stroke]="strokeColor"
									[attr.stroke-width]="strokeWidth" />
								<!-- Triangle pointing to a direct parent. -->
								<polygon [attr.points]="component.inheritanceTriangleSvgPoints(ancestorIndex)" fill="none" [attr.stroke]="strokeColor" [attr.stroke-width]="strokeWidth" />
							</ng-container>

							<!-- Horizontal line pointing to the child -->
							<line stroke-linecap="square"
								[attr.stroke-dasharray]="strokeDashArray"
								[attr.x1]="(diagramConsts.leftMarginForChildLine)"
								[attr.x2]="diagramConsts.mainVerticalLineX + '%'"
								[attr.y1]="component.inheritanceDiagramMiddleOfRowY(rowIndex, rowIndex === inheritancesSection.inheritanceRowsMap.size)"
								[attr.y2]="component.inheritanceDiagramMiddleOfRowY(rowIndex, rowIndex === inheritancesSection.inheritanceRowsMap.size)"
								[attr.stroke]="strokeColor"
								[attr.stroke-width]="strokeWidth" />
						</ng-container>
					</svg>
				</ng-template>

				<div class="inner-headings">
					<div class="inner-heading"></div> <!-- For the transition indicators -->
					<div class="inner-heading"></div> <!-- For the transition indicators -->
					<div class="inner-heading"></div> <!-- For the transition indicators -->
					<div class="inner-heading"></div> <!-- For the inheritance diagram -->
					<div
						class="inner-heading"
						*ngFor="let scopeVariable of requireScopeVariables(); let scopeVariableIndex=index"
					>
						{{scopeVariable.name}}
					</div>
				</div>
				<!-- The decrementing z-index's ensure that the dropdowns within the scope snapshot selectors from the higher rows are not covered by the scope snapshot selectors from the lower rows.	-->
				<div
					class="scope-snapshot-selector-row"
					[ngStyle]="{'z-index': inheritancesSection.inheritanceRowsMap.size - index}"
					[ngClass]="{
						'conflicting-inheritances': inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.CONFLICTING_INHERITANCES,
						'indirect-inheritance': inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.INDIRECTLY_INHERITED,
						'direct-inheritance': inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.DIRECTLY_INHERITED,
						'non-inheritance': inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.NOT_INHERITED,
						'is-changed': component.isInheritanceRowChanged(inheritanceRow),
						'is-unchanged': !component.isInheritanceRowChanged(inheritanceRow),
						'first-inheritance-row': index === 0,
						'last-inheritance-row': index === inheritancesSection.inheritanceRowsMap.size - 1
					}"
					#inheritanceRowElementRefs
					*ngFor="let inheritanceRow of memoizedSortedModifiableInheritanceRows(); let index = index">

					<div
						class="state-transition-indicators"
						[autoHide]="true"
						[pTooltip]="pTooltipForCurrentRowState(inheritanceRow)"
						tooltipPosition="bottom"
						[tooltipOptions]="{
							tooltipStyleClass: 'width-wider'
						}"
						[ngClass]="{
							'initial-state-hidden': !component.isInheritanceRowChanged(inheritanceRow)
						}"
						#stateTransitionIndicators
						>
						<ng-container>
							<div
								class="inheritance-row-state-indicator direct-parent-state initial-state"
								*ngIf="inheritanceRow.loaded.initialStateType === component.inheritanceRowStateTypes.DIRECTLY_INHERITED">
								<div class="ficon ficon-AcceptMedium"></div>
							</div>

							<div
								class="inheritance-row-state-indicator inherited-state initial-state"
								*ngIf="inheritanceRow.loaded.initialStateType === component.inheritanceRowStateTypes.INDIRECTLY_INHERITED">
								<div class="ficon ficon-SplitObject"></div>
							</div>

							<div
								class="inheritance-row-state-indicator not-inherited-state initial-state"
								*ngIf="inheritanceRow.loaded.initialStateType === component.inheritanceRowStateTypes.NOT_INHERITED">
								<div class="ficon ficon-Remove"></div>
							</div>
							<!-- <span class="material-symbols-sharp">
							trending_flat
							</span> -->

							<div
								class="transformation-icon-wrapper">
								<div class="ficon ficon-SortDown transformation-icon"></div>
							</div>
						</ng-container>

						<div class="inheritance-row-state-indicator current-state conflicting-inheritances-state" *ngIf="inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.CONFLICTING_INHERITANCES">
							<div class="icon-wrapper">
								<div class="ficon ficon-Important hidden-spacer"></div>
								<div class="ficon ficon-Important removed-from-document-flow"></div>
							</div>
						</div>

						<div class="inheritance-row-state-indicator current-state direct-parent-state" *ngIf="inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.DIRECTLY_INHERITED">
							<div class="icon-wrapper">
								<div class="ficon ficon-AcceptMedium hidden-spacer"></div>
								<div class="ficon ficon-AcceptMedium removed-from-document-flow"></div>
							</div>
						</div>

						<div class="inheritance-row-state-indicator current-state inherited-state" *ngIf="inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.INDIRECTLY_INHERITED">
							<div class="icon-wrapper">
								<div class="ficon ficon-SplitObject hidden-spacer"></div>
								<div class="ficon ficon-SplitObject removed-from-document-flow"></div>
							</div>
						</div>

						<div class="inheritance-row-state-indicator current-state not-inherited-state" *ngIf="inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.NOT_INHERITED">
							<div class="icon-wrapper">
								<div class="ficon ficon-Remove hidden-spacer"></div>
								<div class="ficon ficon-Remove removed-from-document-flow"></div>
							</div>
						</div>
					</div>

					<!-- Inheritance diagram that shows when hovering over one of the icons on the left. -->
					<div
						class="inheritance-diagram-anchor"
						*ngIf="{
							diagramHeight: component.inheritanceDiagramBottomOfRowY(index),
							nonDirectAncestorIndices: rowsMapToSortedIndices(nonDirectAncestorRowsMapOfChildRow(inheritanceRow)),
							directParentIndices: rowsMapToSortedIndices(directParentRowsMapOfChildRow(inheritanceRow))
						} as vars">
						<div
							class="inheritance-diagram-wrapper"
							[ngStyle]="{'height': vars.diagramHeight + 'px', 'max-height': vars.diagramHeight + 'px'}">
							<ng-container
								*ngTemplateOutlet="
									inheritanceDiagram;
									context:{
										strokeColor: diagramConsts.strokeColorAncestor,
										pointToIndices: vars.nonDirectAncestorIndices,
										strokeDashArray: diagramConsts.ancestorStrokeDashArray,
										strokeWidth: diagramConsts.ancestorStrokeWidth,
										diagramHeight: vars.diagramHeight,
										rowIndex: index
									}">
							</ng-container>
							<ng-container
								*ngTemplateOutlet="
									inheritanceDiagram;
									context:{
										strokeColor: diagramConsts.strokeColorDirectParent,
										pointToIndices: vars.directParentIndices,
										strokeDashArray: diagramConsts.directParentStrokeDashArray,
										strokeWidth: diagramConsts.directParentStrokeWidth,
										diagramHeight: vars.diagramHeight,
										rowIndex: index
									}">
							</ng-container>
						</div>
					</div>


					<!-- The decrementing z-index's ensure that the dropdowns within the scope snapshot selectors from the higher rows are not covered by the scope snapshot selectors from the lower rows. -->
					<app-scope-snapshot-selector
						[state]="inheritanceRow.loaded.selectorState"
						[show-diffs]="true"
						[styles-for-spanning-across-parent-grid]="{
							'first-dropdown': {
								'grid-column-start': component.gridColumnStartOfScopeSnapshotSelector
							}
						}"
						[z-index-for-dropdowns]="inheritancesSection.inheritanceRowsMap.size - index"
						(state-update)="handleParentScopeSnapshotSelectorStateChange($event, inheritanceRow)"
						>
					</app-scope-snapshot-selector>
					<div class="ordinal-input-holder" [ngStyle]="{'z-index': inheritancesSection.inheritanceRowsMap.size - index}" [ngClass]="{'conflicting-ordinal': isConflictingParentOrdinalInModifications(inheritanceRow)}" *ngIf="newlySelectedDirectParentOrdinalInput(inheritanceRow) as ordinal">
						<div class="custom-select enabled" [class]="{'modified': inheritanceRow.loaded.initialParentOrdinal && inheritanceRow.loaded.initialParentOrdinal !== inheritanceRow.loaded.loadedParentOrdinal}">
							<div class="primary-label">
								<ng-container *ngIf="ordinal">
									<ng-container *ngIf="inheritanceRow.loaded.initialParentOrdinal && inheritanceRow.loaded.initialParentOrdinal !== inheritanceRow.loaded.loadedParentOrdinal">
										<span class="initial-parent-ordinal">{{friendlyOrdinal(inheritanceRow.loaded.initialParentOrdinal)}}</span>

										<div class="ficon ficon-SortDown transformation-icon"></div>
									</ng-container>
									<span class="current-parent-ordinal">{{friendlyOrdinal(ordinal)}}</span>
								</ng-container>
								<div class="ficon ficon-ChevronDownMed"></div>
							</div>

							<div class="dropdown-wrapper">
								<div class="dropdown" *ngIf="parentOrdinalDropdownOptions() as dropdownOptions">
									<ng-container *ngFor="let ordinalOption of dropdownOptions">

										<div
											pRipple class="dropdown-option"
											[ngClass]="{
												'initially-selected': ordinalOption === inheritanceRow.loaded.initialParentOrdinal,
												'newly-selected': ordinalOption === ordinal
											}"
											(click)="handleParentScopeSnapshotOrdinalChange($event, inheritanceRow, ordinalOption)"
										>
											{{friendlyOrdinal(ordinalOption)}}
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>

					<div class="inheritance-row-notice error" *ngIf="inheritanceRow.loaded.loadedStateType === component.inheritanceRowStateTypes.CONFLICTING_INHERITANCES">
						<div class="custom-select error modified spaced-secondary-labels">
							<div class="primary-label">
								<div class="label-text">Conflicting inheritances:</div>
							</div>
							<ng-container *ngFor="let possiblyConflictingSs of inheritanceRow.loaded.selectorState.initialScope.scopeSnapshots">
								<div
									class="secondary-label"

									#conflictingInheritanceDirectiveRefs="appScopeSnapshotId"
									[appScopeSnapshotId]="possiblyConflictingSs.id"

									*ngIf="component.findConflictingInheritanceByInheritedScopeSnapshotId(inheritanceRow.loaded.conflictingInheritances, possiblyConflictingSs.id) as conflictingInheritance"
								>
									{{friendlyVersionLabel(possiblyConflictingSs.scopeVersion)}}
								</div>
							</ng-container>
						</div>
					</div>

					<div class="inheritance-row-actions second">
						<div
							class="action-link"
							(click)="viewInheritancesOfAncestor(scopeSnapshot)"
							*ngIf="scopeSnapshotSelectorComponent.selectedScopeSnapshot(inheritanceRow.loaded.selectorState) as scopeSnapshot">
							<div class="ficon ficon-SplitObject"></div> Modify Inheritances of {{friendlyVersionLabel(scopeSnapshot.scopeVersion)}}
						</div>
					</div>

				</div>

				<div class="inheritances-and-base-divider"></div>
				<div
					class="scope-snapshot-selector-row target-scope-snapshot-row"
					[ngClass]="{'is-showing-descendants': isShowingDescendantScopeSnapshots()}"
					#baseRowElementRef #inheritanceRowElementRefs
					*ngIf="requireTargetScopeSnapshot() as targetScopeSnapshot">
					<div
						class="state-transition-indicators"
						[autoHide]="true"
						[tooltipOptions]="{
							tooltipStyleClass: 'width-wider'
						}"
						[pTooltip]="'This is your target scope snapshot.\n\nIt will inherit the flags and values from the highlighted scope snapshots above.'"
						tooltipPosition="bottom"
						#stateTransitionIndicators
					>
						<div class="inheritance-row-state-indicator current-state base-scope-snapshot">
							<div class="icon-wrapper">
								<div class="ficon ficon-BullseyeTargetEdit hidden-spacer"></div>
								<div class="ficon ficon-BullseyeTargetEdit removed-from-document-flow"></div>
							</div>
						</div>
					</div>
					<!-- This anchor is only here so that there's a gap between the column to the left and the column to the right. -->
					<div
						class="inheritance-diagram-anchor"
						[style]="{
							'bottom': '0px',
							'top': '0px',
						}">

						<!-- <ng-container
							*ngTemplateOutlet="
								inheritanceDiagram;
								context:{
									strokeColor: diagramConsts.strokeColorAncestor,
									pointToIndices: rowsMapToSortedIndices(nonDirectAncestorRowsMapOfChildRow(null)),
									strokeDashArray: diagramConsts.ancestorStrokeDashArray,
									strokeWidth: diagramConsts.ancestorStrokeWidth,
									diagramHeight: vars.diagramHeight,
									rowIndex: inheritancesSection.inheritanceRowsMap.size
								}">
						</ng-container>
						<ng-container
							*ngTemplateOutlet="
								inheritanceDiagram;
								context:{
									strokeColor: diagramConsts.strokeColorDirectParent,
									pointToIndices: rowsMapToSortedIndices(directParentRowsMapOfChildRow(null)),
									strokeDashArray: diagramConsts.directParentStrokeDashArray,
									strokeWidth: diagramConsts.directParentStrokeWidth,
									diagramHeight: vars.diagramHeight,
									rowIndex: inheritancesSection.inheritanceRowsMap.size
								}">
						</ng-container> -->

					</div>
					<app-scope-snapshot-selector
						[state]="{
							initialScopeSnapshot: requireTargetScopeSnapshot(),
							actionsWhitelist: [],
							staticity: ssStaticity.STATIC_SCOPE_WITH_STATIC_VERSION,
							scopeVariables: requireScopeVariables()
						}"
						[z-index-for-dropdowns]="200"
						[styles-for-spanning-across-parent-grid]="{
							'first-dropdown': {
								'grid-column-start': component.gridColumnStartOfScopeSnapshotSelector
							}
						}"
					>
					</app-scope-snapshot-selector>
					<!-- Note this z-index should be the same as the z-index-for-dropdowns on the app-scope-snapshot-selector above. Both need to be high enough as to not be covered by the rows in the descendant inheritances section when scrolling. -->
					<div class="inheritance-row-actions" [ngStyle]="{'z-index': 200}">
						<div class="actions-for-descendant-inheritances" [ngClass]="{'showing-descendants': isShowingDescendantScopeSnapshots()}">
							<div class="action-link" (click)="showDescendantScopeSnapshots()" *ngIf="this.inheritancesSection.descendantScopeDifferences.length > 0 && !isShowingDescendantScopeSnapshots()">
								<div class="ficon ficon-Childof"></div>
								<div>See {{this.countDescendantScopeSnapshots(this.inheritancesSection)}} Descendant{{this.countDescendantScopeSnapshots(this.inheritancesSection) !== 1 ? 's' : ''}}</div>
								<div class="ficon ficon-ChevronDownMed"></div>
							</div>
							<div class="action-link" (click)="hideDescendantScopeSnapshots()" *ngIf="isShowingDescendantScopeSnapshots()">
								Hide Descendant{{this.countDescendantScopeSnapshots(this.inheritancesSection) !== 1 ? 's' : ''}} of {{friendlyVersionLabel(targetScopeSnapshot.scopeVersion)}} <div class="ficon ficon-ChevronUpMed"></div>
							</div>
						</div>
					</div>
				</div>

				<div class="inheritances-and-base-divider" *ngIf="isShowingDescendantScopeSnapshots() || hasErrors()"></div>
				<ng-container *ngIf="this.inheritancesSection.descendantScopeDifferences.length > 0 && isShowingDescendantScopeSnapshots()">
					<div class="descendant-inheritances-container" *ngIf="requireTargetScopeSnapshot() as targetScopeSnapshot">
						<app-scope-snapshot-modifiable-descendant-inheritances
							[target-scope-snapshot]="targetScopeSnapshot"
							[descendant-scope-differences]="this.inheritancesSection.descendantScopeDifferences"
							[scope-variables]="requireScopeVariables()"
							[grid-column-start-of-scope-snapshot-selector]="component.gridColumnStartOfScopeSnapshotSelector"
							(view-or-modify-or-discard-ss-inheritances)="viewOrModifyOrDiscardSsInheritances($event)"
						></app-scope-snapshot-modifiable-descendant-inheritances>
					</div>
				</ng-container>
			</div>
			<div class="error-details" *ngIf="hasErrors()">
				<div class="incompatible-flags-and-values">
					<div class="incompatible-flags-section" *ngIf="inheritancesSection.incompatibleFlagNodes.length > 0">
						<div class="subsection-smallcaps-title">{{inheritancesSection.incompatibleFlagNodes.length}} Orphan Flag Node{{inheritancesSection.incompatibleFlagNodes.length !== 1 ? 's' : ''}}:</div>
						<div class="section-description">The following flag nodes (in <span class="red-color-for-legend">red</span>) can no longer reference their parent flag nodes.</div>
						<div class="incompatible-flags">
							<ng-container *ngFor="let ifn of inheritancesSection.incompatibleFlagNodes; let i = index">
								<div class="flag-path-minus-last-flag-node">{{ ifn.flagPath.slice(0, -1) .join(" . ") }} .</div>
								<div class="last-flag-node">{{ ifn.key }}</div>
							</ng-container>
						</div>
					</div>
					<div class="incompatible-values-section" *ngIf="inheritancesSection.incompatibleFlagValues.length > 0">
						<div class="subsection-smallcaps-title">{{inheritancesSection.incompatibleFlagValues.length}} Orphan Flag Value{{inheritancesSection.incompatibleFlagValues.length !== 1 ? 's' : ''}}:</div>
						<div class="section-description">The following flag values (in <span class="red-color-for-legend">red</span>) can no longer reference their flag nodes.</div>
						<div class="incompatible-values">
							<ng-container *ngFor="let ifn of inheritancesSection.incompatibleFlagValues; let i = index">
								<div class="flag-path">{{ ifn.flagPath.join(" . ") }}</div>
								<!-- <div class="flag-value-prefix">=</div> -->
								<div class="flag-value">{{ ifn.value | json }}</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
