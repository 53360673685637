import { Injectable } from '@angular/core';
import _ from 'lodash';
import { ScopeFieldsForScopeSnapshotSelectorFragment, ScopeFieldsForStaticScopeSnapshotSelectorFragment, ScopeSnapshotFieldsForScopeSnapshotSelectorFragment, ScopeVariableFieldsForScopeSnapshotSelectorFragment, ValidatableScopeContainments, ValidatedSsInheritanceModificationsFragment } from 'src/generated/graphql';
import { StaticScopeSnapshotInheritance, ScopeSnapshotInheritancesComponent } from './development/scope-snapshot-inheritances/scope-snapshot-inheritances.component';

export type EssentialValidatableScopeContainment = Pick<ValidatableScopeContainments, 'scopeId' | 'directlyInheritedScopeId' |'containableDirectlyInheritedScopeDefinition' | 'containableScopeDefinition' | 'scopeSnapshotId' | 'directlyInheritedScopeSnapshotId'> &
{
	scopeSnapshot: Pick<NonNullable<ValidatableScopeContainments["scopeSnapshot"]>, 'scopeVersion' | 'createdAt'>,
	directlyInheritedScopeSnapshot: Pick<NonNullable<ValidatableScopeContainments["directlyInheritedScopeSnapshot"]>, 'scopeVersion' | 'createdAt'>,
};

export type ScopeDefinitionMap					= Map<number, Set<number> | undefined>;
export type CompletedScopeDefinitionMap = Map<number, Set<number>>;

@Injectable({
  providedIn: 'root'
})
export class ScopeSnapshotService {

  constructor() { }

	static areDirectInheritancesCompatible(validatableScopeContainments: EssentialValidatableScopeContainment[]): boolean {
		return ScopeSnapshotService.incompatibleDirectInheritances(validatableScopeContainments).length === 0;
	}

	static incompatibleDirectInheritances<VSC extends EssentialValidatableScopeContainment>(validatableScopeContainments: VSC[]): VSC[] {
		return _.filter(validatableScopeContainments, validatableScopeContainment => _.difference(validatableScopeContainment.containableScopeDefinition, validatableScopeContainment.containableDirectlyInheritedScopeDefinition).length !== 0);
	}

	static validatableScopeContainmentsWithModifiedScope<VSC extends EssentialValidatableScopeContainment>(validatableScopeContainments: VSC[], modifyingScopeId: number, modifiedScopeDefinitionMap: CompletedScopeDefinitionMap, scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]): VSC[] {

		// Strings of the form "1-2" or "1-*" where the first number is the scope variable id and the second number is the scope option id. The asterisk indicates that all scope options are targeted for that scope variable.
		const newContainableScopeDefinition: (`${number}-${number | '*'}`)[] = [];

		for (const scopeVariable of scopeVariables) {
			const selectedScopeOptionIdsForVariable = modifiedScopeDefinitionMap.get(scopeVariable.id);

			if (selectedScopeOptionIdsForVariable !== undefined) {
				if (selectedScopeOptionIdsForVariable.size === 0) {
					newContainableScopeDefinition.push(`${scopeVariable.id}-*`);
					for (const scopeOption of scopeVariable.scopeOptions) {
						newContainableScopeDefinition.push(`${scopeVariable.id}-${scopeOption.id}`);
					}
				}
				else {
					for (const scopeOptionId of selectedScopeOptionIdsForVariable) {
						newContainableScopeDefinition.push(`${scopeVariable.id}-${scopeOptionId}`);
					}
				}
			}
		}

		let validatableScopeContainmentsForModifiedDefinition = _.cloneDeep(validatableScopeContainments);

		for (const validatableScopeContainment of validatableScopeContainmentsForModifiedDefinition) {
			if (validatableScopeContainment.scopeId === modifyingScopeId) {
				validatableScopeContainment.containableScopeDefinition = newContainableScopeDefinition;
			}
			if (validatableScopeContainment.directlyInheritedScopeId === modifyingScopeId) {
				validatableScopeContainment.containableDirectlyInheritedScopeDefinition = newContainableScopeDefinition;
			}
		}

		return validatableScopeContainmentsForModifiedDefinition;
	}

	static completedScopeDefinitionMapFromScope(scope: ScopeFieldsForStaticScopeSnapshotSelectorFragment): CompletedScopeDefinitionMap {
		const scopeDefinitionMap: Map<number, Set<number>> = new Map();

		const comparableScopeDefiners = scope.comparableScopeDefiners;
		comparableScopeDefiners.forEach(csd => {
			if (csd.isScopeOptionIdExplicitlySelected) {
				// If the selected scope has the 'All' option selected (which is represented by csd.scopeOption === null but csd.isScopeOptionIdExplicitlySelected being true), then change our set of selected scope option ids from undefined to an empty set, to indicate that the 'All' option is selected for that scope variable.
				if (csd.scopeOption === null) {
					scopeDefinitionMap.set(csd.scopeVariable.id, new Set([]));
				}
				// Otherwise, add the scope option id to our set of selected scope option ids for that scope variable.
				else {
					const scopeOptionIdsForScopeVariable = scopeDefinitionMap.get(csd.scopeVariable.id);
					if (scopeOptionIdsForScopeVariable === undefined) {
						scopeDefinitionMap.set(csd.scopeVariable.id, new Set([csd.scopeOption.id]));
					}
					else {
						scopeOptionIdsForScopeVariable.add(csd.scopeOption.id);
					}
				}
			}
		});
		return scopeDefinitionMap;
	}

	static scopeDefinitionMapFromComparableScopeDefiners(comparableScopeDefiners: ScopeFieldsForScopeSnapshotSelectorFragment["comparableScopeDefiners"], scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]): ScopeDefinitionMap {
		const scopeDefinitionMap: Map<number, Set<number> | undefined> = new Map();
		scopeVariables.forEach(scopeVariable => {
			scopeDefinitionMap.set(scopeVariable.id, undefined);
		});

		comparableScopeDefiners.forEach(csd => {
			if (csd.isScopeOptionIdExplicitlySelected) {
				// If the selected scope has the 'All' option selected (which is represented by csd.scopeOption === null but csd.isScopeOptionIdExplicitlySelected being true), then change our set of selected scope option ids from undefined to an empty set, to indicate that the 'All' option is selected for that scope variable.
				if (csd.scopeOption === null) {
					scopeDefinitionMap.set(csd.scopeVariable.id, new Set([]));
				}
				// Otherwise, add the scope option id to our set of selected scope option ids for that scope variable.
				else {
					const scopeOptionIdsForScopeVariable = scopeDefinitionMap.get(csd.scopeVariable.id);
					if (scopeOptionIdsForScopeVariable === undefined) {
						scopeDefinitionMap.set(csd.scopeVariable.id, new Set([csd.scopeOption.id]));
					}
					else {
						scopeOptionIdsForScopeVariable.add(csd.scopeOption.id);
					}
				}
			}
		});
		return scopeDefinitionMap;
	}

	static completedScopeDefinitionMapToOptionIds(completedScopeDefinitionMap: CompletedScopeDefinitionMap): Set<number> {
		const flattenedFullySelectedScopeOptionIds = new Set<number>();
		for (const scopeOptionIdsForScopeVariable of completedScopeDefinitionMap.values()) {
			scopeOptionIdsForScopeVariable.forEach(scopeOptionId => flattenedFullySelectedScopeOptionIds.add(scopeOptionId));
		}
		return flattenedFullySelectedScopeOptionIds;
	}

	static formatInheritablesToScopeForSelector(
		inheritableScope: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["ancestorScope"],
		inheritableScopeSnapshots: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["ancestorScopeSnapshots"]
		): ScopeFieldsForScopeSnapshotSelectorFragment {

			return {
				...inheritableScope,
				comparableScopeDefiners: inheritableScope.comparableScopeDefiners.map(comparableScopeDefiner => ({
					...comparableScopeDefiner,
					__typename: "ComparableScopeDefiners",
					scopeVariable: {
						...comparableScopeDefiner.scopeVariable,
						__typename: "ScopeVariables",
					},
					scopeOption: (comparableScopeDefiner.scopeOption) ? {
						...comparableScopeDefiner.scopeOption,
						__typename: "ScopeOptions",
					} : null
				})),
				__typename: "Scopes",
				scopeSnapshots: inheritableScopeSnapshots.map(iss => ({
					...iss,
					__typename: "ScopeSnapshots"
				}))
			}
	}

	static formatInheritablesToScopeSnapshotForSelector(
		targetScopeSnapshotId: number,
		inheritableScope: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["ancestorScope"],
		inheritableScopeSnapshots: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number]["ancestorScopeDifferences"][number]["ancestorScopeSnapshots"]
		): ScopeSnapshotFieldsForScopeSnapshotSelectorFragment {

		const inheritableScopeSnapshot = inheritableScopeSnapshots.find(iss => iss.id === targetScopeSnapshotId);
		if (!inheritableScopeSnapshot) {
			throw new Error("Could not find the inheritable scope snapshot for the base scope snapshot id " + targetScopeSnapshotId);
		}

		return {
			...inheritableScopeSnapshot,
			__typename: "ScopeSnapshots",
			scope: ScopeSnapshotService.formatInheritablesToScopeForSelector(inheritableScope, inheritableScopeSnapshots)
		}
	}

	static validatedInheritancesToStaticInheritances(validatedInheritances: ValidatedSsInheritanceModificationsFragment["validationsByTargetScopeSnapshots"][number], scopeVariables: ScopeVariableFieldsForScopeSnapshotSelectorFragment[]): StaticScopeSnapshotInheritance[] {
		const staticInheritances: StaticScopeSnapshotInheritance[] = [];
		for (const inheritanceRowDetails of validatedInheritances.ancestorScopeDifferences) {
			const savedInheritance = inheritanceRowDetails.savedInheritance;
			const isExistingNonSelfInheritance = savedInheritance && !savedInheritance.isSelf;

			if (isExistingNonSelfInheritance) {
				staticInheritances.push({
					...savedInheritance,
					inheritedScopeSnapshot: ScopeSnapshotService.formatInheritablesToScopeSnapshotForSelector(validatedInheritances.targetScopeSnapshot.id, inheritanceRowDetails.ancestorScope, inheritanceRowDetails.ancestorScopeSnapshots),
				});
			}
		}

		return staticInheritances;
	}
}
