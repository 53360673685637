import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, OnDestroy, InjectionToken, Inject, Host, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import _ from 'lodash';
import { CrudStateService, CrudTypes, EditableStateModel, GqlRequestInfo, ModelType, State } from 'src/app/crud-state.service';
import { requireVar } from 'src/app/utilities';
import { FlagNodeTypesEnum, FlagNodes, FlagNodesInsertInput, FlagNodesUpdates, SaveFlagMutation, SaveFlagMutationVariables, ScopeFlagNodes, ScopeFlagNodesUpdates, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment } from 'src/generated/graphql';
import { FlagNodeFromScopeSnapshotResponse, ExpectedScopeSnapshotData, BulkFlagSelector, BulkValueSelector, BulkValueSelectorChange, BulkFlagSelectorChange, NonSelfInheritance, SelfInheritance } from '../scope-snapshot-page/scope-snapshot-page.component';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/app-common/common.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FlagValueEditableModel } from '../flag-value/flag-value.component';
import { CheckboxChangeEvent } from 'primeng/checkbox';

// TODO If we ever want to display this flag component outside of the scope snapshot page, we'll need to only rely on a small subset of the scope snapshot data, such as the id, scopeId, and scopeVersion, rather than ExpectedScopeSnapshotData["scopeSnapshotsByPk"] which comes from the scope snapshot page.
type ExpectedScopeSnapshot = ExpectedScopeSnapshotData["scopeSnapshotsByPk"];

type EditableModelKeys = {
	// This flag key, as identified by a flag node, represents the leaf node of the flag row.
	Flag: FlagNodeFromScopeSnapshotResponse,
	// This flag node key on the other hand, also identified by a flag node, represents any node of the flag row.
	FlagNode: FlagNodeFromScopeSnapshotResponse,
}

type EditableModels = {
	Flag: FormGroup<{}>,
	FlagNode: FormGroup<{
		key: FormControl<string | null>,
		type: FormControl<FlagNodes["type"]>,
		ordinal: FormControl<number | null>
	}>,
}

type CreatableModelKeys = {
	Flag: {},
	FlagNode: {
		flagRowKey: GenericizedFlagKey,
		index: number
	}
}

type CreatableModels = {
	Flag: FormGroup<{}>,
	FlagNode: FormGroup<{
		key: FormControl<string | null>,
		type: FormControl<FlagNodes["type"]>,
		ordinal: FormControl<number | null>,
	}>,
}

// Format for inserting new flag nodes using a graphql mutation.
type GqlInsertableFlagNode = {
	parentFlagNodeScopeSnapshotId: number | null,
	parentScopeFlagNodeId: number | null,
	// parentFlagNodeType: FlagNodes["type"] | null,
	key: string | null,
	type: FlagNodes["type"],
	ordinal: number | null,
	definedInScopeSnapshotId: number,
	scopeFlagNode: { // TODO See if passing in this scopeFlagNode object to the mutation is sufficient enough that we don't have to pass in the FlagNode.definedInScopeId field.
		data: {
			scopeId: number,
			// isArray: boolean,
			// supportsChildren: boolean,
		}
	},
	children?: {data: [Omit<GqlInsertableFlagNode, "parentScopeFlagNodeId" | "parentFlagNodeScopeSnapshotId" | "parentFlagNodeType">]},
}

type ComponentGqlRequestInfos = {
	mutations: {
		saveFlag: GqlRequestInfo<SaveFlagMutation, SaveFlagMutationVariables>
	}
};

type FlagCrudTypes = CrudTypes<EditableModelKeys["Flag"], EditableModels["Flag"], CreatableModelKeys["Flag"], CreatableModels["Flag"]>;
type GenericizedFlagKey = FlagCrudTypes["genericizedModelKey"];

type FlagNodeCrudTypes = CrudTypes<EditableModelKeys["FlagNode"], EditableModels["FlagNode"], CreatableModelKeys["FlagNode"], CreatableModels["FlagNode"]>;
type GenericizedFlagNodeKey = FlagNodeCrudTypes["genericizedModelKey"];

type FlagRow = {
	flagKey: GenericizedFlagKey, // Leaf flag node for existing flags, or empty object for new flag
	flagNodeKeys: GenericizedFlagNodeKey[], // All flag nodes within a flag row
	flagKeyChildren: GenericizedFlagKey[], // Child flag nodes of the leaf flag node within the current scope snapshot. This is used to determine whether or not the flag row is expandable.
};

const crudModelTransformers: {
	flagToEditable: FlagCrudTypes["transformers"]["modelToEditable"],
	flagNodeToEditable: FlagNodeCrudTypes["transformers"]["modelToEditable"],
	creatableFlag: FlagCrudTypes["transformers"]["keyToCreatable"],
	creatableFlagNode: FlagNodeCrudTypes["transformers"]["keyToCreatable"],
}
= {
	flagToEditable: (_flag): EditableModels["Flag"] => {
		return new FormGroup({});
	},
	flagNodeToEditable: (flagNode): EditableModels["FlagNode"] => {
		const formGroup = new FormGroup({
			key: new FormControl<string | null>(flagNode.key, {nonNullable: true}),
			type: new FormControl(flagNode.type, {nonNullable: true}),
			ordinal: new FormControl<number | null>(flagNode.ordinal, {nonNullable: true})
		});

		// We need to mark the key input as touched in order to avoid the error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value for 'ng-untouched': 'true'. Current value: 'false'.
		// This error happens because we are using an [autofocus] directive on the key input, which causes the input to be focused and therefore touched, which causes the error to be thrown.
		// By marking the key input as touched, we are telling Angular that the input has already been interacted with by the user, and therefore prevents the error from being thrown.
		formGroup.controls.key.markAsTouched();

		return formGroup;
	},
	creatableFlag: (_key): CreatableModels["Flag"] => {
		return new FormGroup({});
	},
	creatableFlagNode: (key): CreatableModels["FlagNode"] => {
		const formGroup = new FormGroup({
			key: new FormControl<string | null>(null, {nonNullable: true}),
			type: new FormControl(FlagNodeTypesEnum.Value, {nonNullable: true}),
			ordinal: new FormControl<number | null>(null, {nonNullable: true}), // TODO Implement this when we support ordinal.
		});

		// We need to mark the key input as touched in order to avoid the error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value for 'ng-untouched': 'true'. Current value: 'false'.
		// This error happens because we are using an [autofocus] directive on the key input, which causes the input to be focused and therefore touched, which causes the error to be thrown.
		// By marking the key input as touched, we are telling Angular that the input has already been interacted with by the user, and therefore prevents the error from being thrown.
		formGroup.controls.key.markAsTouched();

		return formGroup;
	}
};

const injectionTokens: {
	flag: FlagCrudTypes["injectionTokens"],
	flagNode: FlagNodeCrudTypes["injectionTokens"],
} = {
	flag: {
		keyToCreatableModelTransformer: new InjectionToken("Flag Key To Creatable Model Transformer"),
		modelToEditableModelTransformer: new InjectionToken("Flag Model To Editable Model Transformer"),
	},
	flagNode: {
		keyToCreatableModelTransformer: new InjectionToken("Flag Node Key To Creatable Model Transformer"),
		modelToEditableModelTransformer: new InjectionToken("Flag Node Model To Editable Model Transformer"),
	},
}

const CrudClasses: {
	Flag: FlagCrudTypes["crudClass"]
	FlagNode: FlagNodeCrudTypes["crudClass"],
}
=
{
	Flag: (CrudStateService<EditableModelKeys["Flag"], EditableModels["Flag"], CreatableModelKeys["Flag"], CreatableModels["Flag"]>),
	FlagNode: (CrudStateService<EditableModelKeys["FlagNode"], EditableModels["FlagNode"], CreatableModelKeys["FlagNode"], CreatableModels["FlagNode"]>),
}

@Component({
	selector: 'app-flags[current-scope-snapshot][flag-nodes-map][leaf-flag-node-ids][inheritances][page-number][page-size]',
	templateUrl: './flags.component.html',
	styleUrls: ['./flags.component.scss'],
	providers: [
		{
			provide: injectionTokens.flag.modelToEditableModelTransformer,
			useValue: crudModelTransformers["flagToEditable"],
		},
		{
			provide: injectionTokens.flag.keyToCreatableModelTransformer,
			useValue: crudModelTransformers["creatableFlag"],
		},
		{
			provide: "flagCrud",

			useFactory: (
				toEditableModelTransformer: (FlagCrudTypes["transformers"]["modelToEditable"]),
				toCreatableModelTransformer: (FlagCrudTypes["transformers"]["keyToCreatable"])
			) => new CrudClasses["Flag"](toEditableModelTransformer, toCreatableModelTransformer),

			deps: [injectionTokens.flag.modelToEditableModelTransformer, injectionTokens.flag.keyToCreatableModelTransformer]
		},
		{
			provide:  injectionTokens.flagNode.modelToEditableModelTransformer,
			useValue: crudModelTransformers["flagNodeToEditable"],
		},
		{
			provide: injectionTokens.flagNode.keyToCreatableModelTransformer,
			useValue: crudModelTransformers["creatableFlagNode"],
		},
		{
			provide: "flagNodeCrud",

			useFactory: (
				toEditableModelTransformer: (FlagNodeCrudTypes["transformers"]["modelToEditable"]),
				toCreatableModelTransformer: (FlagNodeCrudTypes["transformers"]["keyToCreatable"])
			) => new CrudClasses["FlagNode"](toEditableModelTransformer, toCreatableModelTransformer),

			deps: [injectionTokens.flagNode.modelToEditableModelTransformer, injectionTokens.flagNode.keyToCreatableModelTransformer]
		},
	]
})
export class FlagsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input('leaf-flag-node-ids') leafFlagNodeIds?: FlagNodeFromScopeSnapshotResponse["id"][];
	@Input('flag-nodes-map') flagNodesMap?: {
		[key: FlagNodeFromScopeSnapshotResponse["id"]]: FlagNodeFromScopeSnapshotResponse
	};
	@Input('current-scope-snapshot') currentScopeSnapshot?: ExpectedScopeSnapshot;
	@Input('use-parent-grid') useParentGrid = false;
	@Input('bulk-flag-selectors') bulkFlagSelectors?: {
		[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkFlagSelector;
	};
	@Input('bulk-value-selectors') bulkValueSelectors?: {
		[key: FlagNodeFromScopeSnapshotResponse["id"]]: BulkValueSelector;
	};
	@Input('inheritances') inheritances?: [...NonSelfInheritance[], SelfInheritance];
	@Input('page-number') pageNumber?: number;
	@Input('page-size') pageSize?: number;

	@Output('save') saveEvent = new EventEmitter<true>();
	@Output('bulk-flag-selector-change') bulkFlagSelectorEvent = new EventEmitter<BulkFlagSelectorChange>();
	@Output('bulk-value-selector-change') bulkValueSelectorEvent = new EventEmitter<BulkValueSelectorChange>();
	@Output('flag-node-ids-to-paginate') flagNodeIdsToPaginateEvent = new EventEmitter<FlagNodeFromScopeSnapshotResponse["id"][]>();

	flagNodeTypeDropdown?: OverlayPanel;
	allFlagNodeTypes = FlagNodeTypesEnum;
	flagValueStateModels: {
		[key: number]: EditableStateModel<FlagValueEditableModel>
	} = {};

	friendlyVersionLabel = CommonService.friendlyVersionLabel;

	// savedFlag;
	// @ViewChild("nodeInputContainer") nodeInputContainer: HTMLElement;
	// @ViewChild("valueInputContainer") valueInputContainer: HTMLElement;

	gqlRequestInfos: ComponentGqlRequestInfos = {
		mutations: {
			saveFlag: {
				subscription: null,
				gql: gql`
					mutation saveFlag(
						$deleteFlagNodeIds: [Int!]! = [],
						$updateFlagNodes: [FlagNodesUpdates!]! = [],
						$insertFlagNodes: [FlagNodesInsertInput!]! = []
						) {
						deleteFlagNodes(where: {id: {_in: $deleteFlagNodeIds}}) {
							returning {
								id
							}
						}
						updateFlagNodesMany(updates: $updateFlagNodes) {
							returning {
								id
							}
						}
						insertFlagNodes(objects: $insertFlagNodes) {
							returning {
								key
								type
								ordinal
							}
						}
					}
				`
			}
		}
	};

	constructor(
		private apollo: Apollo,
		private commonService: CommonService,
		private cd: ChangeDetectorRef,
		@Host() @Inject("flagCrud") public flagCrud: FlagCrudTypes["crud"],
		@Host() @Inject("flagNodeCrud") public flagNodeCrud: FlagNodeCrudTypes["crud"]
	) {}

	ngOnInit() {console.log("ngOnInit flags component")}

	requireCurrentScopeSnapshot(): ExpectedScopeSnapshot {
		return requireVar(this.currentScopeSnapshot);
	}

	requireInheritances(): [...NonSelfInheritance[], SelfInheritance] {
		return requireVar(this.inheritances);
	}

	private emptyCaches() {
		this.flagCrud.discardAllModels();
		this.flagNodeCrud.discardAllModels();
		this.emptyFlagRowsCache();
		this.flagValuesCountCaches = new Map();
		this.childFlagNodesCountCaches = new Map();
		this.flagValueStateModels = {};
		this.cd.detectChanges();
	}

	emptyFlagRowsCache() {
		this.flagRowsCaches = new Map();
	}

	ngOnChanges(changes: SimpleChanges) {
		// Note changes.currentScopeSnapshot is actually a SimpleChange object. But changes.currentScopeSnapshot.currentValue is the actual new scope snapshot object, which can be undefined, in the case that no scope snapshot was selected.
		// In either case, we want to empty the caches, because the scope snapshot has changed.
		// console.log("changes:", changes)
		if (changes.currentScopeSnapshot !== undefined && changes.currentScopeSnapshot.previousValue && changes.currentScopeSnapshot.currentValue && changes.currentScopeSnapshot.previousValue.id !== changes.currentScopeSnapshot.currentValue.id) {
			this.emptyCaches();
		}
		else {
			// If the leaf flag node ids or flag nodes map have changed, then empty the caches.
			if (
				(changes.leafFlagNodeIds !== undefined && !_.isEqual(changes.leafFlagNodeIds.previousValue, changes.leafFlagNodeIds.currentValue)) ||
				(changes.flagNodesMap !== undefined && !_.isEqual(changes.flagNodesMap.previousValue, changes.flagNodesMap.currentValue))
				) {
				this.emptyCaches();
			}
		}
	}

	ngOnDestroy(): void {
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
	}

	ngAfterViewInit(){
		//console.log("title:", this.title.nativeElement)
	}

	/****************
	 * FLAG METHODS *
	 ****************/

	private leafFlagNodes(): FlagNodeFromScopeSnapshotResponse[] {
		let leafFlagNodes: FlagNodeFromScopeSnapshotResponse[] = [];

		if (this.flagNodesMap !== undefined && this.leafFlagNodeIds !== undefined){
			for (const leafFlagNodeId of this.leafFlagNodeIds) {
				const leafFlagNode = this.flagNodesMap[leafFlagNodeId];
				if (leafFlagNode === undefined){
					throw Error("The flag nodes map does not contain the leaf flag node id: " + leafFlagNodeId + ".");
				}
				else {
					leafFlagNodes.push(leafFlagNode);
				}
			}
		}
		return leafFlagNodes;
	}

	private flagRowsCaches: Map<string, FlagRow[]> = new Map();
	private expandedFlagNodeIds: Set<number> = new Set();

	// TODO Figure out a nice way to refactor all memoized functions into possibly a decorator or something.
	memoizedFlagRows(
		fresh: boolean,
		): FlagRow[] {
		const cacheKey = JSON.stringify({expanded: this.expandedFlagNodeIds, pageSize: this.pageSize, pageNumber: this.pageNumber});
		const existingFlagRowsCache = this.flagRowsCaches.get(cacheKey);

		if (fresh || !existingFlagRowsCache) {
			const newFlagRowsCache = this.flagRows();
			this.flagRowsCaches.set(cacheKey, newFlagRowsCache);
			const allExistingFlagNodesFilteredByExpandedFlagNodes = this.allExistingFlagNodesFilteredByExpandedFlagNodes();
			this.flagNodeIdsToPaginateEvent.emit(allExistingFlagNodesFilteredByExpandedFlagNodes.map(flagNode => flagNode.id));
			return newFlagRowsCache;
		}
		return existingFlagRowsCache;
	}


	expandFlagRow(editableFlagKey: EditableModelKeys["Flag"]): void {
		this.expandedFlagNodeIds.add(editableFlagKey.id);
		this.emptyFlagRowsCache();
	}

	isFlagRowExpanded(editableFlagKey: EditableModelKeys["Flag"]): boolean {
		return this.expandedFlagNodeIds.has(editableFlagKey.id);
	}

	isFlagRowExpandable(flagRow: FlagRow): boolean {
			return flagRow.flagKey.type === 'EDITABLE' && flagRow.flagKeyChildren.length > 0 && !this.isFlagRowExpanded(flagRow.flagKey.editableModelKey);
	}

	isFlagRowCollapsible(flagRow: FlagRow): boolean {
		return flagRow.flagKey.type === 'EDITABLE' && this.isFlagRowExpanded(flagRow.flagKey.editableModelKey);
	}

	collapseFlagRow(editableFlagKey: EditableModelKeys["Flag"]): void {
		this.expandedFlagNodeIds.delete(editableFlagKey.id);
		this.emptyFlagRowsCache();
	}

	private requireFlagNodesMap(): {
		[key: FlagNodeFromScopeSnapshotResponse["id"]]: FlagNodeFromScopeSnapshotResponse
	} {
		return requireVar(this.flagNodesMap);
	}

	private flagRows(): FlagRow[] {
		const filteredByExpandedFlagNodes = this.allExistingFlagNodesFilteredByExpandedFlagNodes();

		const pageNumber = requireVar(this.pageNumber);
		const pageSize = requireVar(this.pageSize);
		const startIndex = pageNumber * pageSize;
		const endIndex = startIndex + pageSize;

		const pageSlicedFlagNodes = filteredByExpandedFlagNodes.slice(startIndex, endIndex);

		const flagRows = pageSlicedFlagNodes.map(leafFlagNode => this.flagRow({type: ModelType.EDITABLE, editableModelKey: leafFlagNode}));
		// Add an empty flag key for the creatable flag row.
		flagRows.push(this.flagRow({type: ModelType.CREATABLE, creatableModelKey: {}}));

		return flagRows;
	};

	private allExistingFlagNodesFilteredByExpandedFlagNodes(): FlagNodeFromScopeSnapshotResponse[] {
		let flagNodes: FlagNodeFromScopeSnapshotResponse[] = this.leafFlagNodes();

		const flagNodesMap = this.requireFlagNodesMap();

		const filteredByExpandedFlagNodes = flagNodes.filter((flagNode, index) => {
			let currFlagNode = flagNode;
			while (currFlagNode.parent && this.expandedFlagNodeIds.has(currFlagNode.parent.id)) {
				const tempCurrFlagNode = flagNodesMap[currFlagNode.parent.id];
				if (tempCurrFlagNode === undefined){
					throw Error("The flag nodes map does not contain the flag node id: " + currFlagNode.parent.id + ".");
				}

				currFlagNode = tempCurrFlagNode;
			}
			return !currFlagNode.parent;
		});

		return filteredByExpandedFlagNodes;
	}

	// TODO Memoize this function for performance.
	private flagRow(flagKey: GenericizedFlagKey): FlagRow {
		return {
			flagKey: flagKey,
			flagNodeKeys: this.flagNodeKeysForFlagRow(flagKey, true),
			flagKeyChildren: this.flagKeyChildrenForFlagRow(flagKey)
		};
	}

	private flagKeyChildrenForFlagRow(flagKey: GenericizedFlagKey): GenericizedFlagNodeKey[] {
		if (flagKey.type === ModelType.EDITABLE) {
			const leafFlagNode = flagKey.editableModelKey;
			const flagNodesMap = this.requireFlagNodesMap();
			return Object.values(flagNodesMap).filter(flagNode => flagNode.parent && flagNode.parent.id === leafFlagNode.id).map(flagNode => ({type: ModelType.EDITABLE, editableModelKey: flagNode}));
		}
		else {
			return [];
		}
	}

	memoizedFlagNodeKeysForFlagRow(fresh: boolean, creatableOrEditableFlagKey: GenericizedFlagKey): GenericizedFlagNodeKey[] {
		const flagRowsInCache = this.memoizedFlagRows(fresh);
		const existingFlagRow = flagRowsInCache.find(flagRow => _.isEqual(flagRow.flagKey, creatableOrEditableFlagKey));

		if (existingFlagRow === undefined) {
			throw Error("The flag rows cache does not contain the flag row with flag key: " + JSON.stringify(creatableOrEditableFlagKey) + ".");
		}

		if (fresh){
			existingFlagRow["flagNodeKeys"] = this.flagNodeKeysForFlagRow(creatableOrEditableFlagKey, true);
		}
		return existingFlagRow["flagNodeKeys"];
	}

	// private flagNodeKeysForFlagRowCache: Map<GenericizedFlagKey, GenericizedFlagNodeKey[]> = new Map();

	// Get the flag node keys for the flag row, in order. First, the existing flag nodes will be in order from ancestor flag node down to descendant
	// flag node, followed by the new flag nodes in the same order (from ancestor flag node down to descendant flag node).
	// Other methods (such as saveFlag()) expect the flag node keys to be in order to work properly, so make sure to preserve the order if you modify
	// this method.
	private flagNodeKeysForFlagRow(creatableOrEditableFlagKey: GenericizedFlagKey, includeMarkedForDeletion: boolean): GenericizedFlagNodeKey[] {
		const flagNodesMap = this.requireFlagNodesMap();

		let flagNodeKeysForFlagRow: GenericizedFlagNodeKey[] = [];

		// If creatableOrEditableFlagKey tells us that we're editing an existing flag, start by listing it's flag nodes.
		if (creatableOrEditableFlagKey.type === ModelType.EDITABLE) {
			const leafFlagNode = creatableOrEditableFlagKey.editableModelKey;

			// Traverse the flag nodes from the leaf flag node down to the root flag node.
			let currFlagNode: FlagNodeFromScopeSnapshotResponse | null = flagNodesMap[leafFlagNode.id] || null;
			if (!currFlagNode) {
				throw Error("The flag nodes map does not contain the flag node id: " + leafFlagNode.id + ".");
			}

			while (currFlagNode) {
				// If the flag node isn't marked as deleted, then add it to the beginning of the list.
				if (!this.flagNodeCrud.isMarkedForDeletion(currFlagNode) || includeMarkedForDeletion){
					const genericizedEditableFlagNodeKey = {type: ModelType.EDITABLE as const, editableModelKey: currFlagNode};
					flagNodeKeysForFlagRow.unshift(genericizedEditableFlagNodeKey);
				}

				// Initialize currFlagNode to the parent flag node (if any) in preparation for the next iteration.
				if (currFlagNode.parent) {
					if (!flagNodesMap[currFlagNode.parent.id]) {
						throw Error("The flag nodes map does not contain the flag node id: " + currFlagNode.parent.id + ".");
					}
					currFlagNode = flagNodesMap[currFlagNode.parent.id] || null;
				}
				else {
					currFlagNode = null;
				}
			}
		}

		// Then, list the newly created flag nodes for the flag row, if any.
		const creatableFlagNodeKeys = this.flagNodeCrud.creatableModelKeys({flagRowKey: creatableOrEditableFlagKey}).sort((a, b) => (a.index - b.index));
		const genericizedCreatableFlagNodeKeys = creatableFlagNodeKeys.map(creatableFlagNodeKey => ({type: ModelType.CREATABLE as const, creatableModelKey: creatableFlagNodeKey}));

		return flagNodeKeysForFlagRow.concat(genericizedCreatableFlagNodeKeys);
	}

	cancelFlag(flagKey: GenericizedFlagKey): void {
		this.flagCrud.discardGeneric(flagKey);

		const flagNodeKeys = this.flagNodeKeysForFlagRow(flagKey, true);
		flagNodeKeys.forEach(flagNodeKey => {
			this.flagNodeCrud.discardGeneric(flagNodeKey);
		});
		this.emptyFlagRowsCache();
	}

	isPreviousFlagNodeAnArray(flagKey: GenericizedFlagKey, flagNodeKey: GenericizedFlagNodeKey): boolean {
		const allFlagNodeKeys = this.flagNodeKeysForFlagRow(flagKey, false);
		const previousFlagNodeKey = allFlagNodeKeys[allFlagNodeKeys.findIndex(currFlagNodeKey => _.isEqual(currFlagNodeKey, flagNodeKey)) - 1];
		if (previousFlagNodeKey) {
			const editablePreviousFlagNode = this.flagNodeCrud.creatableOrEditable(previousFlagNodeKey);
			if (editablePreviousFlagNode) {
				return editablePreviousFlagNode.model.controls.type.value === FlagNodeTypesEnum.Array;
			}
			else {
				return previousFlagNodeKey.type === ModelType.EDITABLE && previousFlagNodeKey.editableModelKey.type === FlagNodeTypesEnum.Array;
			}
		}
		return false;
	}

	private maxArrayIndex = 999;

	handleInsertCharInFlagNodeKeyInput(event: KeyboardEvent, flagKey: GenericizedFlagKey, flagNodeKey: GenericizedFlagNodeKey): void {
		const isPreviousFlagNodeAnArray = this.isPreviousFlagNodeAnArray(flagKey, flagNodeKey);
		if (isPreviousFlagNodeAnArray) {
			const ignoreKeys = " ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`-=[]\\;',./~!@#$%^&*()_+{}|:\"<>?";
			const isIgnoredKey = ignoreKeys.includes(event.key);
			if (isIgnoredKey) {
				event.preventDefault();
			}
			else if ("1234567890".includes(event.key)) {
				const keyControl = this.flagNodeCrud.requireCreatableOrEditable(flagNodeKey).model.controls.key;
				const newKey = parseInt(keyControl.value + event.key);
				if (newKey > this.maxArrayIndex) {
					event.preventDefault();
				}
				else {
					if (newKey === 0) {
						keyControl.setValue("0"); // This helps converts strings like "00" to "0".
						event.preventDefault();
					}
					if (typeof(keyControl.value) === "string" && keyControl.value[0] === "0") {
						event.preventDefault(); // Prevents the user from entering a number like "01".
					}
				}
			}
		}
	}

	saveFlag(flagKey: GenericizedFlagKey): void {
		const currentScopeSnapshot = requireVar(this.currentScopeSnapshot);
		// Get the flag node keys for the flag row, in order. The existing flag nodes will be in order from ancestor flag node down to descendant flag
		// node, followed by the new flag nodes with the same order (from ancestor flag node down to descendant flag node).
		const flagNodeKeys = this.flagNodeKeysForFlagRow(flagKey, true);

		let updateNodes: FlagNodesUpdates[] = [];
		let deleteNodeIds: number[] = [];
		let insertNode: GqlInsertableFlagNode | null = null;
		let currNestedInsertNode: Omit<GqlInsertableFlagNode, "parentFlagNodeScopeSnapshotId" | "parentScopeFlagNodeId"> | null = null;

		let parentFlagNodeForFirstCreatableNode: FlagNodeFromScopeSnapshotResponse | null = null;

		// Iterate through the edited nodes and created nodes and determine which ones need to be updated, deleted, and inserted.
		// As mentioned before, edited nodes appear first in the list, followed by created nodes.
		for (const flagNodeKey of flagNodeKeys) {
			// Find the last node that already exists and isn't marked for deletion so that we can use it as the parent node for the first creatable node.
			if (flagNodeKey.type === ModelType.EDITABLE && !this.flagNodeCrud.isMarkedForDeletion(flagNodeKey.editableModelKey)) {
				parentFlagNodeForFirstCreatableNode = flagNodeKey.editableModelKey;
			}

			// Record those dirty nodes that need to be updated, deleted, or inserted. Creatable nodes are always considered dirty.
			if (this.flagNodeCrud.genericIsDirty(flagNodeKey)){
				if (flagNodeKey.type === ModelType.EDITABLE &&
					flagNodeKey.editableModelKey.definedInScopeSnapshotId === currentScopeSnapshot.id // This check is not actually needed since we would never allow editing of a node in a different scope snapshot, but is here just in case to ensure we never accidentally allow it.
				){
					// Add this node to the beginning of the list of deleted node ids. This is because we want to delete the descendant nodes first, then the
					// ancestor nodes, in order to avoid foreign key constraint errors.
					if (this.flagNodeCrud.isMarkedForDeletion(flagNodeKey.editableModelKey)){
						deleteNodeIds.unshift(flagNodeKey.editableModelKey.id);
					}
					// If the node is not deleted, then add it to the list of nodes to update.
					else {
						const editable = this.flagNodeCrud.requireEditable(flagNodeKey.editableModelKey);
						updateNodes.push({
							_set: {
								key: editable.model.controls.key.value,
								type: editable.model.controls.type.value,
								ordinal: editable.model.controls.ordinal.value,
							},
							where: {
								id: {
									_eq: flagNodeKey.editableModelKey.id
								}
							}
						});
					}
				}
				// If the node is new (if it's a creatable), then create a new insert node for it. This will be our one and only insert node at the top level
				// of the mutation. Every following new node in the flag will be nested inside the previously inserted node.
				else if (flagNodeKey.type === ModelType.CREATABLE){
					const creatable = this.flagNodeCrud.requireCreatable(flagNodeKey.creatableModelKey);

					const commonFieldsForAllInsertNodes = {
						key: creatable.model.controls.key.value,
						ordinal: creatable.model.controls.ordinal.value,
						definedInScopeId: currentScopeSnapshot.scopeId,
						definedInScopeSnapshotId: currentScopeSnapshot.id,
						type: creatable.model.controls.type.value,
						scopeFlagNode: {
							data: {
								scopeId: currentScopeSnapshot.scopeId,
							}
						}
					};

					// Create our first insert node, if we haven't already.
					if (!insertNode){
						insertNode = {
							parentFlagNodeScopeSnapshotId: parentFlagNodeForFirstCreatableNode ? parentFlagNodeForFirstCreatableNode.definedInScopeSnapshotId : null,
							parentScopeFlagNodeId: parentFlagNodeForFirstCreatableNode ? parentFlagNodeForFirstCreatableNode.scopeFlagNodeId : null,
							// parentFlagNodeType: parentFlagNodeForFirstCreatableNode ? parentFlagNodeForFirstCreatableNode.type : null,
							...commonFieldsForAllInsertNodes,
						};

						currNestedInsertNode = insertNode;
					}
					// If we have already created our first insert node, then create a new insert node and nest it inside the previous one.
					// The fields "parentScopeFlagNodeId" and "parentFlagNodeScopeSnapshotId" are omitted because they will be automatically set by the API based on the parent node.
					else if (currNestedInsertNode){
						const newCurrNestedInsertNode: Omit<GqlInsertableFlagNode, "parentScopeFlagNodeId" | "parentFlagNodeScopeSnapshotId" | "parentFlagNodeType"> = {
							...commonFieldsForAllInsertNodes,
						};

						currNestedInsertNode.children = {
							data: [newCurrNestedInsertNode]
						};
						currNestedInsertNode = newCurrNestedInsertNode;
					}
				}
			}
		}

		const insertFlagNodesGql = [];
		if (insertNode){
			insertFlagNodesGql.push(insertNode);
		}

		const saveFlagInfo = this.gqlRequestInfos.mutations.saveFlag;
		saveFlagInfo.subscription?.unsubscribe();

		saveFlagInfo.subscription = this.apollo.mutate({
			mutation: saveFlagInfo.gql,
			variables: {
				deleteFlagNodeIds: deleteNodeIds,
				updateFlagNodes: updateNodes,
				insertFlagNodes: insertFlagNodesGql,
			}
		}).subscribe({
			next: (data) => {
				this.emptyCaches();
				this.saveEvent.emit(true);
			},
			error: (error) => this.commonService.mutationErrorHandler(error)
		});
	}

	// TODO This becomes inefficient when there are ~5000 flags.
	flagNodeLabel(flagNodeKey: GenericizedFlagNodeKey): string | null {
		if (flagNodeKey.type === ModelType.EDITABLE) {
			const editable = this.flagNodeCrud.editable(flagNodeKey.editableModelKey);
			if (editable) {
				return editable.model.controls.key.value;
			}
			return flagNodeKey.editableModelKey.key;
		}
		else if (flagNodeKey.type === ModelType.CREATABLE){
			const creatable = this.flagNodeCrud.creatable(flagNodeKey.creatableModelKey);
			if (creatable) {
				return creatable.model.controls.key.value;
			}
		}
		return null;
	}

	private childFlagNodesCountCaches: Map<string, number | null> = new Map();
	memoizedChildFlagNodesCount(fresh: boolean, flagKey: GenericizedFlagKey, inCurrentScopeSnapshotOnly: boolean): number | null {
		if (flagKey.type !== ModelType.EDITABLE){
			return null;
		}
		const cacheKey = JSON.stringify({flagKey: flagKey.editableModelKey.id, inCurrentScopeSnapshotOnly: inCurrentScopeSnapshotOnly});
		const existingChildFlagNodesCountCache = this.childFlagNodesCountCaches.get(cacheKey);

		if (fresh || !existingChildFlagNodesCountCache) {
			const newChildFlagNodesCountCache = this.childFlagNodesCount(flagKey, inCurrentScopeSnapshotOnly);
			this.childFlagNodesCountCaches.set(cacheKey, newChildFlagNodesCountCache);
			return newChildFlagNodesCountCache;
		}
		return existingChildFlagNodesCountCache;
	}

	childFlagNodesCount(flagKey: GenericizedFlagKey, inCurrentScopeSnapshotOnly: boolean): number | null {
		if (flagKey.type === ModelType.EDITABLE) {
			if (!inCurrentScopeSnapshotOnly){
				return flagKey.editableModelKey.childrenAggregate.aggregate.count;
			}
			else {
				const flagNodesMap = this.requireFlagNodesMap();
				return _.values(flagNodesMap).filter(flagNode => flagNode.parent && flagNode.parent.id === flagKey.editableModelKey.id).length;
			}
		}
		return null;
	}

	private flagValuesCountCaches: Map<string, number | null> = new Map();
	memoizedFlagValuesCount(fresh: boolean, flagKey: GenericizedFlagKey, inCurrentAndInheritedScopeSnapshotsOnly: boolean): number | null {
		if (flagKey.type !== ModelType.EDITABLE){
			return null;
		}

		const cacheKey = JSON.stringify({flagKey: flagKey.editableModelKey.id, inCurrentAndInheritedScopeSnapshotsOnly: inCurrentAndInheritedScopeSnapshotsOnly});
		const existingFlagValuesCountCache = this.flagValuesCountCaches.get(cacheKey);

		if (fresh || !existingFlagValuesCountCache) {
			const newFlagValuesCountCache = this.flagValuesCount(flagKey, inCurrentAndInheritedScopeSnapshotsOnly);
			this.flagValuesCountCaches.set(cacheKey, newFlagValuesCountCache);
			return newFlagValuesCountCache;
		}
		return existingFlagValuesCountCache;
	}

	flagValuesCount(flagKey: GenericizedFlagKey, inCurrentAndInheritedScopeSnapshotsOnly: boolean): number | null {
		if (flagKey.type === ModelType.EDITABLE) {
			if (!inCurrentAndInheritedScopeSnapshotsOnly){
				return flagKey.editableModelKey.flagValuesAggregate.aggregate.count;
			}
			else {
				const inheritedScopeSnapshotIds = this.requireInheritances().map(inheritance => inheritance.inheritedScopeSnapshotId);

				return flagKey.editableModelKey.flagValues.filter(flagValue => inheritedScopeSnapshotIds.includes(flagValue.appliedInScopeSnapshotId)).length;
			}
		}
		return null;
	}

	childFlagNodesNotice(flagKey: GenericizedFlagKey): string | null {
		if (flagKey.type !== ModelType.EDITABLE){
			return null;
		}
		const childFlagNodesCount = this.memoizedChildFlagNodesCount(false, flagKey, false);

		if (childFlagNodesCount && childFlagNodesCount > 0){
			const childFlagNodesInCurrentScopeSnapshotCount = this.memoizedChildFlagNodesCount(false, flagKey, true);
			if (childFlagNodesInCurrentScopeSnapshotCount === null) {
				throw Error("Could not determine the number of child flag nodes in the current scope snapshot for flag key: " + JSON.stringify(flagKey) + ".");
			}
			const childFlagNodesCountElsewhere = childFlagNodesCount - childFlagNodesInCurrentScopeSnapshotCount;
			return `This flag has:\n\n▪ ${childFlagNodesInCurrentScopeSnapshotCount} child flag${childFlagNodesInCurrentScopeSnapshotCount !== 1 ? "s" : ""} in this scope snapshot and its inheritances\n\n▪ ${childFlagNodesCountElsewhere} child flag${childFlagNodesCountElsewhere !== 1 ? "s" : ""} in ${childFlagNodesCountElsewhere === 1 ? "an" : ""}other scope snapshot${childFlagNodesCountElsewhere !== 1 ? "s" : ""}`;
		}

		return null;
	}

	flagValuesNotice(flagKey: GenericizedFlagKey): string | null {
		const flagValuesCount = this.memoizedFlagValuesCount(false, flagKey, false);

		if (flagKey.type !== ModelType.EDITABLE){
			return null;
		}

		if (flagValuesCount && flagValuesCount > 0){
			const flagValuesCountInCurrentAndInheritedScopeSnapshots = this.memoizedFlagValuesCount(false, flagKey, true);
			if (flagValuesCountInCurrentAndInheritedScopeSnapshots === null) {
				throw Error("Could not determine the number of flag values in the current and inherited scope snapshots for flag key: " + JSON.stringify(flagKey) + ".");
			}
			const flagValuesCountElsewhere = flagValuesCount - flagValuesCountInCurrentAndInheritedScopeSnapshots;

			return `This flag has:\n\n▪ ${flagValuesCountInCurrentAndInheritedScopeSnapshots} flag value${flagValuesCountInCurrentAndInheritedScopeSnapshots !== 1 ? "s" : ""} in this scope snapshot and its inheritances\n\n▪ ${flagValuesCountElsewhere} flag value${flagValuesCountElsewhere !== 1 ? "s" : ""} in ${flagValuesCountElsewhere === 1 ? "an" : ""}other scope snapshot${flagValuesCountElsewhere !== 1 ? "s" : ""}`;
		}

		return null;
	}

	visibleReplicatedFlagNodesCount(flagKey: GenericizedFlagKey): number | null {
		if (flagKey.type === ModelType.EDITABLE && flagKey.editableModelKey.definedInScopeSnapshotId === this.requireCurrentScopeSnapshot().id) {
			return flagKey.editableModelKey.scopeFlagNode.flagNodes.length;
		}
		return null;
	}

	replicatedFlagNodes(flagKey: GenericizedFlagKey): Extract<GenericizedFlagKey, {type: ModelType.EDITABLE}>["editableModelKey"]["scopeFlagNode"]["flagNodes"] | null {
		if (flagKey.type === ModelType.EDITABLE) {
			return flagKey.editableModelKey.scopeFlagNode.flagNodes;
		}
		return null;
	}

	isDeleteNodeInFlagRowAllowed(flagKey: GenericizedFlagKey, nodeKey: GenericizedFlagNodeKey, usingForView = true): boolean {
		if (usingForView && (!this.flagCrud.genericInCreateOrEditState(flagKey) || !this.flagNodeCrud.genericInCreateOrEditState(nodeKey))){
			return false;
		}
		if (this.isInheritedNode(nodeKey)){
			return false;
		}

		const allFlagNodeKeysExcludingDeleted = this.memoizedFlagNodeKeysForFlagRow(false, flagKey).filter((currNodeKey) => !this.flagNodeCrud.genericIsDeleted(currNodeKey));

		const isLastNode = _.isEqual(allFlagNodeKeysExcludingDeleted[allFlagNodeKeysExcludingDeleted.length - 1], nodeKey);

		// Only allow deleting the last node when using this method for the view.
		if (usingForView && !isLastNode){
			return false;
		}

		if (nodeKey.type === ModelType.CREATABLE){
			return true;
		}
		else if (nodeKey.type === ModelType.EDITABLE){
			const hasNoFlagValues = nodeKey.editableModelKey.flagValuesAggregate.aggregate.count === 0;
			// Only allow deleting an existing node if it has no child nodes and no flag values.
			if (hasNoFlagValues){
				const numChildren = nodeKey.editableModelKey.childrenAggregate.aggregate.count;
				if (numChildren === 0){
					return true;
				}
				// If the node only has one child, then we can delete the node if the child is marked as deleted as a result of this flag row being modified.
				else if (numChildren === 1){
					const allFlagNodeKeysIncludingDeleted = this.memoizedFlagNodeKeysForFlagRow(false, flagKey);
					const nodeKeyIndex = allFlagNodeKeysIncludingDeleted.findIndex((currNodeKey) => _.isEqual(currNodeKey, nodeKey));
					const expectedDeletedNodeKey = allFlagNodeKeysIncludingDeleted[nodeKeyIndex + 1];

					if (nodeKeyIndex > -1 && expectedDeletedNodeKey){
						return this.flagNodeCrud.genericIsDeleted(expectedDeletedNodeKey);
					}
				}
			}
		}
		return false;
	}

	createOrEditFlag(flagKey: GenericizedFlagKey): void {
		this.flagCrud.genericCreateOrEdit(flagKey, true);
		const flagNodeKeys = this.flagNodeKeysForFlagRow(flagKey, false)
		// Edit the last flag node in the flag row.
		let editNodeIndex = flagNodeKeys.length - 1;

		// If there are no flag nodes in the flag row, or if the last flag node is not allowed to be edited (probably because it's inherited), then try to create a new flag node.
		if (flagNodeKeys.length === 0 || !this.isCreateOrEditNodeInFlagRowAllowed(flagKey, editNodeIndex)){
			editNodeIndex += 1;
		}
		// Only create a new flag node if we're allowed to create a new flag node. This may not be possible if the last existing flag node is neither an object nor an array.
		if (this.isCreateOrEditNodeInFlagRowAllowed(flagKey, editNodeIndex)) {
			this.createOrEditNodeInFlagRow(flagKey, editNodeIndex);
		}
	}

	deleteFlag(flagKey: GenericizedFlagKey): void {
		if (this.isDeleteFlagAllowed(flagKey)){
			const nodeKeys = this.flagNodeKeysForFlagRow(flagKey, false);
			for (const nodeKey of nodeKeys){
				if (nodeKey.type === ModelType.EDITABLE){
					const nodeHasNoDependents = nodeKey.editableModelKey.flagValuesAggregate.aggregate.count === 0 && nodeKey.editableModelKey.childrenAggregate.aggregate.count === 0;
					if (nodeHasNoDependents) {
						this.flagNodeCrud.delete(nodeKey.editableModelKey);
					}
				}
			}
			this.saveFlag(flagKey);
		}
	}

	isDeleteFlagAllowed(flagKey: GenericizedFlagKey): boolean {
		// Do not allow deleting flags from other scope snapshots.
		if (!this.isFlagFromCurrentScopeSnapshot(flagKey)) {
			return false;
		}

		// Do not allow deleting flags where the flag row contains new child nodes that have not yet been saved.
		const creatables = this.flagNodeCrud.creatables({flagRowKey: flagKey});
		if (creatables.size > 0){
			return false;
		}

		if (flagKey.type === ModelType.EDITABLE){
			const leafFlagNode = flagKey.editableModelKey;
			// Only allow deleting an existing flag if the leaf node has no existing child nodes and no existing flag values.
			if (leafFlagNode.childrenAggregate.aggregate.count === 0 && leafFlagNode.flagValuesAggregate.aggregate.count === 0){
				return true;
			}
		}
		return false;
	}

	isFlagFromCurrentScopeSnapshot(flagKey: GenericizedFlagKey): boolean {
		if (flagKey.type === ModelType.EDITABLE){
			return this.isNodeFromCurrentScopeSnapshot(flagKey);
		}
		else if (flagKey.type === ModelType.CREATABLE){
			return true;
		}
		return false;
	}

	isNodeFromCurrentScopeSnapshot(nodeKey: GenericizedFlagNodeKey): boolean {
		if (nodeKey.type === ModelType.EDITABLE){
			const currentScopeSnapshot = requireVar(this.currentScopeSnapshot);
			return nodeKey.editableModelKey.definedInScopeSnapshotId === currentScopeSnapshot.id;
		}
		else if (nodeKey.type === ModelType.CREATABLE){
			return true;
		}
		return false;
	}

	scopeSnapshotFromNode(nodeKey: GenericizedFlagNodeKey): ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment {
		if (nodeKey.type === ModelType.EDITABLE) {
			for (const inheritance of this.requireInheritances()) {
				if (inheritance.inheritedScopeSnapshot.id === nodeKey.editableModelKey.definedInScopeSnapshotId) {
					return inheritance.inheritedScopeSnapshot;
				}
			}
			throw Error("Could not find the scope snapshot for the node key: " + JSON.stringify(nodeKey) + ".");
		}
		else {
			return requireVar(this.currentScopeSnapshot);
		}
	}

	createOrEditNodeInFlagRow(flagKey: GenericizedFlagKey, index: number){
		if (!this.isCreateOrEditNodeInFlagRowAllowed(flagKey, index)){
			throw new Error("Creating or editing the specified node in the flag row is not allowed.");
		}

		const allFlagNodeKeys = this.flagNodeKeysForFlagRow(flagKey, false);

		// Set all current flag nodes in the flag row to a view state.
		allFlagNodeKeys.forEach((flagNode) => !this.flagNodeCrud.genericInCreateOrEditState(flagNode) || this.flagNodeCrud.genericView(flagNode));

		// If the sibling flag node exists in the flag row, then put it in an edit state.
		if (0 <= index && index < allFlagNodeKeys.length){
			const siblingFlagNodeKey = allFlagNodeKeys[index];
			if (siblingFlagNodeKey) {
				this.flagNodeCrud.genericCreateOrEdit(siblingFlagNodeKey, true);
			}
		}
		// Otherwise, create a new flag node and put it in an edit state.
		else if (index == allFlagNodeKeys.length){
			// First, find the last creatable flag node, if any, and get its index within the creatable flag nodes. Note that this "creatableFlagNodeIndex"
			// is different than the index passed in: It is the index within the creatable flag nodes in the flag row, as opposed to all flag nodes in the
			// flag row.
			const lastCreatableFlagNodeIndex = _.maxBy(this.flagNodeCrud.creatableModelKeys({flagRowKey: flagKey}), (flagNode) => flagNode.index)?.index;
			// Generate a new creatable flag node index. If there are no creatable flag nodes, then the new creatable flag node index is 0. Otherwise, it is
			// the last creatable flag node index plus 1.
			const newCreatableFlagNodeIndex = lastCreatableFlagNodeIndex != undefined ? lastCreatableFlagNodeIndex + 1 : 0;
			// Create a new flag node in the flag row, using the new creatable flag node index as part of the key.
			const newFlagNode = this.flagNodeCrud.create({flagRowKey: flagKey, index: newCreatableFlagNodeIndex}, false);

			// Set the previous flag node's type to either 'object' or 'array', since we just added a new child node to it.
			const previousFlagNodeKey = allFlagNodeKeys[index - 1];
			if (previousFlagNodeKey) {
				const genericStateModel = this.flagNodeCrud.genericCreateOrEdit(previousFlagNodeKey, true, State.VIEW);
				const creatableOrEditableModel = genericStateModel.model;

				const previousFlagNodeType = creatableOrEditableModel.controls.type;
				// Only set the previous flag node's type to 'object' or 'array' if it's not already set to 'object' or 'array'.
				if (![FlagNodeTypesEnum.Array, FlagNodeTypesEnum.Object].includes(previousFlagNodeType.value)) {
					previousFlagNodeType.setValue(FlagNodeTypesEnum.Object); // Default to 'object' if the previous flag node's type is not already set.
				}

				if (previousFlagNodeType.value === FlagNodeTypesEnum.Array) {
					newFlagNode.model.controls.key.setValue("0"); // Initialize the new flag node's key to 0 since the parent flag node is an array.
				}
			}

			// // Refresh the flag rows cache so that the new flag node shows up in the flag row.
			// if (this.flagRowsCache){
			// 	const newFlagRow = this.flagRow(flagKey);

			// 	let indexOfExistingFlagRow: number;
			// 	if (flagKey.type === ModelType.EDITABLE){
			// 		indexOfExistingFlagRow = this.flagRowsCache.findIndex(
			// 			(currFlagRow) => currFlagRow.flagKey.type === ModelType.EDITABLE && currFlagRow.flagKey.editableModelKey.id === flagKey.editableModelKey.id
			// 		);
			// 	}
			// 	else {
			// 		indexOfExistingFlagRow = this.flagRowsCache.findIndex(
			// 			(currFlagRow) => currFlagRow.flagKey.type === ModelType.CREATABLE
			// 		);
			// 	}
			// 	if (indexOfExistingFlagRow === -1){
			// 		throw new Error("The flag rows cache does not contain the flag row with the flag key: " + flagKey + ".");
			// 	}
			// 	this.flagRowsCache[indexOfExistingFlagRow] = newFlagRow;
			// }
			this.emptyFlagRowsCache();

		}
	}

	// Only allow appending a new flag node to a flag if the last flag node does not support values.
	// Allow editing flag nodes that belong to the current scope snapshot.
	isCreateOrEditNodeInFlagRowAllowed(flagKey: GenericizedFlagKey, index: number) {
		const currentScopeSnapshot = requireVar(this.currentScopeSnapshot);
		if (!this.flagCrud.genericInCreateOrEditState(flagKey)){
			return false;
		}
		const allFlagNodeKeysExcludingDeleted = this.memoizedFlagNodeKeysForFlagRow(false, flagKey).filter((currNodeKey) => !this.flagNodeCrud.genericIsDeleted(currNodeKey));
		const indexForNewFlagNode = allFlagNodeKeysExcludingDeleted.length;

		// If we're appending a new flag node to the flag row, then only allow it if the last flag node in the flag row supports child flag nodes (if it's an array or object).
		if (index === indexForNewFlagNode) {
			// If there are no flag nodes in the flag row, then allow creating the first flag node.
			const lastFlagNodeKey = allFlagNodeKeysExcludingDeleted[allFlagNodeKeysExcludingDeleted.length - 1];
			if (lastFlagNodeKey === undefined) {
				return true;
			}

			const lastFlagNode = this.flagNodeCrud.creatableOrEditable(lastFlagNodeKey);
			if (lastFlagNode){

				const lastFlagNodeIsObjectOrArray = [FlagNodeTypesEnum.Array, FlagNodeTypesEnum.Object].includes(lastFlagNode.model.controls.type.value);
				if (!lastFlagNodeIsObjectOrArray){
					return false;
				}

				const lastFlagNodeHasEitherNameOrOrdinal = !!lastFlagNode.model.controls.key.value || !!lastFlagNode.model.controls.ordinal.value;
				if (!lastFlagNodeHasEitherNameOrOrdinal){
					return false;
				}
			}
			else {
				if (lastFlagNodeKey.type === ModelType.CREATABLE){
					// This shouldn't really happen because if the last existing flag node is a creatable, then that means that this.flagNodeCrud.isGenericEditableExists(lastFlagNodeKey) should've been true, which would've allowed us to do the necessary checks in the above if statement instead.
					return false;
				}
				else if (lastFlagNodeKey.type === ModelType.EDITABLE){
					const lastFlagNode = lastFlagNodeKey.editableModelKey;
					const lastFlagNodeIsObjectOrArray = [FlagNodeTypesEnum.Array, FlagNodeTypesEnum.Object].includes(lastFlagNode.type);
					if (!lastFlagNodeIsObjectOrArray){
						return false;
					}

					const lastFlagNodeHasEitherNameOrOrdinal = !!lastFlagNode.key || !!lastFlagNode.ordinal;
					if (!lastFlagNodeHasEitherNameOrOrdinal){
						return false;
					}
				}
			}
			return true;
		}
		// Otherwise, only allow editing the flag node if it belongs to the current scope snapshot.
		else {
			const flagNodeKey = allFlagNodeKeysExcludingDeleted[index];

			if (flagNodeKey) {
				if (flagNodeKey.type === ModelType.CREATABLE){
					return true;
				}
				else if (flagNodeKey.type === ModelType.EDITABLE){
					return flagNodeKey.editableModelKey.definedInScopeSnapshotId === currentScopeSnapshot.id;
				}
			}
			else {
				return false;
			}
		}
	}

	createOrEditNodeInFlagRowEvent($event: Event, flagKey: GenericizedFlagKey, index: number){
		if (this.isCreateOrEditNodeInFlagRowAllowed(flagKey, index)){
			$event.preventDefault();
			this.createOrEditNodeInFlagRow(flagKey, index);
		}
	}

	isLastInheritedNodeInFlagRow(flagKey: GenericizedFlagKey, nodeKey: GenericizedFlagNodeKey | undefined): boolean {
		if (!nodeKey){
			return false;
		}

		const allFlagNodeKeysExcludingDeleted = this.memoizedFlagNodeKeysForFlagRow(false, flagKey).filter((currNodeKey) => !this.flagNodeCrud.genericIsDeleted(currNodeKey));
		const lastInheritedNodeIndex = _.findLastIndex(allFlagNodeKeysExcludingDeleted, (flagNodeKey) => {
			if (flagNodeKey.type === ModelType.CREATABLE){
				return false;
			}
			else if (flagNodeKey.type === ModelType.EDITABLE){
				return this.isInheritedNode(flagNodeKey);
			}
			return false;
		});

		return lastInheritedNodeIndex !== -1 && lastInheritedNodeIndex === allFlagNodeKeysExcludingDeleted.findIndex(
			(currNodeKey) => {
				if (currNodeKey.type === ModelType.CREATABLE){
					return false;
				}
				else if (currNodeKey.type === ModelType.EDITABLE && nodeKey.type === ModelType.EDITABLE){
					return currNodeKey.editableModelKey.id === nodeKey.editableModelKey.id;
				}
			});
	}

	isInheritedNode(nodeKey: GenericizedFlagNodeKey): boolean {
		if (nodeKey.type === ModelType.CREATABLE){
			return false;
		}
		else if (nodeKey.type === ModelType.EDITABLE){
			const currentScopeSnapshot = requireVar(this.currentScopeSnapshot);
			if (!currentScopeSnapshot) {
				throw new Error("Current scope snapshot is not set.");
			}
			return nodeKey.editableModelKey.definedInScopeSnapshotId !== currentScopeSnapshot.id;
		}
		return false;
	}

	flagNodeTypeForView(nodeKey: GenericizedFlagNodeKey): FlagNodeTypesEnum {
		const creatableOrEditable = this.flagNodeCrud.creatableOrEditable(nodeKey);
		if (creatableOrEditable){
			return creatableOrEditable.model.controls.type.value;
		}
		else {
			if (nodeKey.type === ModelType.EDITABLE){
				return nodeKey.editableModelKey.type;
			}
			else {// if (nodeKey.type === ModelType.CREATABLE){
				// This shouldn't really happen, but the compiler doesn't know that.
				throw new Error("Creatable flag node does not exist.");
			}
		}
	}

	flagNodeTypes(flagNodeKey: GenericizedFlagNodeKey): FlagNodeTypesEnum[] {
		if (flagNodeKey.type === ModelType.EDITABLE){
			if (flagNodeKey.editableModelKey.childrenAggregate.aggregate.count === 0 && flagNodeKey.editableModelKey.flagValuesAggregate.aggregate.count === 0){
				return [
					FlagNodeTypesEnum.Array,
					FlagNodeTypesEnum.Object,
					FlagNodeTypesEnum.Value
				];
			}
			else if (flagNodeKey.editableModelKey.childrenAggregate.aggregate.count > 0){
				return [
					FlagNodeTypesEnum.Array,
					FlagNodeTypesEnum.Object,
				];
			}
			else if (flagNodeKey.editableModelKey.flagValuesAggregate.aggregate.count > 0){
				return [
					FlagNodeTypesEnum.Value
				];
			}
			else {
				// This should never really happen. It means that a flag node has both child flag nodes and flag values, which is not allowed in the database. But we need to return something here, so just return an empty array.
				return [];
			}
		}
		else {
			return [
				FlagNodeTypesEnum.Array,
				FlagNodeTypesEnum.Object,
				FlagNodeTypesEnum.Value
			];
		}
	}

	flagNodeTypeLabel(flagNodeType: FlagNodeTypesEnum): string {
		if (flagNodeType === FlagNodeTypesEnum.Object){
			return "Object";
		}
		else if (flagNodeType === FlagNodeTypesEnum.Array){
			return "Array";
		}
		else {
			return "Leaf";
		}
	}

	handleFlagValueStateChange(leafFlagNodeId: number, stateModel: EditableStateModel<FlagValueEditableModel> | null) {
		if (stateModel === null){
			delete this.flagValueStateModels[leafFlagNodeId];
		}
		else {
			this.flagValueStateModels[leafFlagNodeId] = stateModel;
		}
	}

	isFlagRowFlagDisabled(flagKey: GenericizedFlagKey): boolean {
		const thisFlagInEditState = this.flagCrud.genericInCreateOrEditState(flagKey);
		if (thisFlagInEditState) {
			return false;
		}

		const thisFlagsValueInEditState = this.isFlagValueInEditState(flagKey);

		if (thisFlagsValueInEditState) {
			return false;
		}

		return this.flagCrud.areAnyInCreateOrEditState() || _.values(this.flagValueStateModels).length > 0;
	}

	areFlagRowFlagActionsDisabled(flagKey: GenericizedFlagKey): boolean {
		if (this.isFlagRowFlagDisabled(flagKey)){
			return true;
		}

		return this.isFlagValueInEditState(flagKey) || this.bulkFlagSelectors !== undefined;
	}

	isFlagRowValueActionsDisabled(flagKey: GenericizedFlagKey): boolean {
		if (this.isFlagRowValueDisabled(flagKey)){
			return true;
		}

		return this.bulkValueSelectors !== undefined;
	}

	private isFlagValueInEditState(flagKey: GenericizedFlagKey): boolean {
		if (flagKey.type !== ModelType.EDITABLE){
			return false;
		}
		const leafFlagNodeId = flagKey.editableModelKey.id;

		const editableStateModel = this.flagValueStateModels[leafFlagNodeId];
		if (!editableStateModel){
			return false;
		}

		return editableStateModel.state === 'CREATE_OR_EDIT';
	}

	isFlagRowValueDisabled(flagKey: GenericizedFlagKey): boolean {
		const areAnyFlagsInCreateOrEditState = this.flagCrud.areAnyInCreateOrEditState();
		if (areAnyFlagsInCreateOrEditState){
			return true;
		}

		const thisValueInEditState = this.isFlagValueInEditState(flagKey);

		if (thisValueInEditState) {
			return false;
		}

		return _.values(this.flagValueStateModels).length > 0;
	}
	areBulkFlagSelectorsEnabled(): boolean {
		return !!this.bulkFlagSelectors;
	}

	bulkFlagSelector(flagKey: GenericizedFlagKey): BulkFlagSelector | undefined {
		if (flagKey.type === ModelType.EDITABLE){
			return this.bulkFlagSelectors?.[flagKey.editableModelKey.id];
		}
	}

	handleBulkFlagSelectorCheckboxChange(event: CheckboxChangeEvent, flagNodeId: EditableModelKeys["FlagNode"]["id"], selector: Extract<BulkFlagSelector, {selectorType: 'CHECKBOX'}>): void {
		selector.checked = event.checked;

		this.bulkFlagSelectorEvent.emit({
			flagNodeId: flagNodeId,
			selector: selector,
		});
	}

	bulkValueSelector(flagKey: GenericizedFlagKey): BulkValueSelector | undefined {
		if (flagKey.type === ModelType.EDITABLE){
			return this.bulkValueSelectors?.[flagKey.editableModelKey.id];
		}
	}

	handleBulkValueSelectorCheckboxChange(event: CheckboxChangeEvent, flagNodeId: EditableModelKeys["FlagNode"]["id"], selector: Extract<BulkValueSelector, {selectorType: 'CHECKBOX'}>): void {
		selector.checked = event.checked;

		this.bulkValueSelectorEvent.emit({
			flagNodeId: flagNodeId,
			selector: selector,
		});
	}

	areBulkValueSelectorsEnabled(): boolean {
		return !!this.bulkValueSelectors;
	}

}
