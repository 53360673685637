<div
	class="scope-snapshot-selector"
	*ngIf="state"
	[ngStyle]='{"grid-template-columns": "repeat(" + (state.scopeVariables.length + ((isSnapshotsSelectorPresent() || self.versionInputtableModificationsOrReturnUndefined(state)) ? 1 : 0)) + ", auto)"}'
	[class]="{
		'display-within-parent-grid': stylesForSpanningAcrossParentGrid !== undefined,
		'is-cherry-picking-for-replication': self.isSelectingFlagsAndValuesToReplicate(state)
	}">
	<div class="scope-variables-with-options" [ngClass]="{'selectable': self.isScopeDefinitionSelectable(state)}">
		<div
			class="custom-select-wrapper"
			[ngStyle]="ngStyleForScopeOptionsDropdown(scopeVariableIndex)"
			*ngFor="let scopeVariable of state.scopeVariables; let scopeVariableIndex = index"
		>
			<div class="disabler-overlay" *ngIf="self.isSelectorDisabled(state)"></div>
			<ng-container *ngIf="scopeVariableIndex === state.scopeVariables.length - 1 && drawSnapshotToScopeConnectors as connectorInfo">
				<svg
					class="connector-from-scope-to-snapshot"
					height="100%"
					[ngStyle]="{
						width: 'calc(100% - ' + (connectorInfo.distanceFromSnapshotToMidlinePx - 1) + 'px)',
					}">
					<line stroke-linecap="square"
						[attr.x1]="'0%'"
						[attr.x2]="'100%'"
						[attr.y1]="'50%'"
						[attr.y2]="'50%'"
						[attr.stroke-width]="strokeWidthForScopeToSnapshotConnector"
						[attr.stroke]="'#cccccc'" />
				</svg>
			</ng-container>
			<div
				class="custom-select"
				[ngClass]="{
					'enabled': self.isScopeDefinitionSelectable(state),
					'modified': state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION && self.isScopeVariableSelectionsDirty(state, scopeVariable.id),
					'unselected': self.scopeDefinitionMap(state).get(scopeVariable.id) === undefined
				}"
			>

				<!-- Display the scope variable name, as "Select environment". Or, if at least one option is selected, then it'll say "environment". -->
				<div class="primary-label no-dirty-indicator">
					<ng-container *ngIf="self.scopeDefinitionMap(state).get(scopeVariable.id) === undefined">
						<div class="label-text">Select {{scopeVariable.name}}</div><div class="ficon ficon-ChevronDownMed"></div>
					</ng-container>
					<ng-container *ngIf="self.scopeDefinitionMap(state).get(scopeVariable.id) !== undefined">
						<div class="label-text">{{scopeVariable.name}}</div>
					</ng-container>
				</div>

				<!-- Display the selected options for the scope variable in one line -->
				<div class="secondary-label">
					<ng-template
						#selectedOptionsForScopeVariableTemplate
						let-typeUnsafeState="state"
						let-typeUnsafeScopeVariable="scopeVariable"
						let-showAsOlderState="showAsOlderState"
					>
						<ng-container *ngIf="typedState(typeUnsafeState); let state"><ng-container *ngIf="typedScopeVariable(typeUnsafeScopeVariable); let scopeVariable">
							<ng-container *ngIf="self.scopeDefinitionMap(state).get(scopeVariable.id) !== undefined">
								<div
									class="ficon"
									[ngClass]="{
										'ficon-ClearFilter': self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id),
										'ficon-QueryList': !self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id),
										'show-as-older-state': showAsOlderState
									}">
								</div>

								<div
									class="selected-options"
									[ngClass]="{
										'all-option-selected': self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id),
										'show-as-older-state': showAsOlderState
									}"
									>

									<ng-container *ngIf="self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id)">
										All
									</ng-container>
									<ng-container *ngIf="self.scopeDefinitionMap(state).get(scopeVariable.id); let scopeOptionIds">
										{{ scopeOptionValuesByIds(scopeOptionIds).join(', ') }}
									</ng-container>

								</div>

							</ng-container>
						</ng-container></ng-container>

					</ng-template>

					<!-- Only show the old selected option in the format "oldValue -> newValue" if the showDiffs flag is true and there exists an oldValue and a newValue. If the showDiffs flag is false though, then just show the newValue (if it exists). -->
					<ng-container
						*ngIf="
							state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION &&
							showDiffs &&
							self.scopeDefinitionMap(state).get(scopeVariable.id) !== undefined &&
							self.isScopeVariableSelectionsDirty(state, scopeVariable.id) &&
							self.initialStateFromState(state);
							let initialState
						">
						<ng-container *ngIf="self.scopeDefinitionMap(initialState).get(scopeVariable.id) !== undefined">

							<ng-container *ngTemplateOutlet="selectedOptionsForScopeVariableTemplate; context: {state: initialState, scopeVariable: scopeVariable, showAsOlderState: true}"></ng-container>
							<div class="ficon ficon-SortDown transformation-icon"></div>
							<!-- <span class="material-icons-sharp transformation-icon">
								trending_flat
							</span> -->

						</ng-container>
					</ng-container>
					<ng-container *ngTemplateOutlet="selectedOptionsForScopeVariableTemplate; context: {state: state, scopeVariable: scopeVariable}"></ng-container>
				</div>

				<!-- Dropdown menu containing all the options for the scope variable when hovered over -->

				<div class="dropdown-wrapper">
					<div class="dropdown" *ngIf="self.isScopeDefinitionSelectable(state)">
						<!-- Display the 'All' option -->
						<label pRipple class="dropdown-option" [ngClass]="{
							'initially-selected': self.isAllScopeOptionSelectedForScopeVariable(self.initialStateFromState(state), scopeVariable.id),
							'newly-selected': self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id)
						}">
							<p-checkbox [binary]="true" [ngModel]="self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id)" (onChange)="clickAllScopeOption($event, scopeVariable.id)" /><span class="text-label">All</span>
						</label>
						<!-- Display the specific options available for the scope variable -->
						<div class="group-title" *ngIf="scopeVariable.scopeOptions.length > 0">Singles</div>
						<ng-container *ngFor="let scopeOption of scopeVariable.scopeOptions">
							<label pRipple class="dropdown-option" [ngClass]="{
								'initially-selected': self.isScopeOptionSelected(self.initialStateFromState(state), scopeOption.id),
								'newly-selected': self.isScopeOptionSelected(state, scopeOption.id),
								'implicitly-selected': self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id)
							}">
								<p-checkbox [ngModel]="self.isScopeOptionSelected(state, scopeOption.id)" (onChange)="clickScopeOption($event, scopeOption.id)" [binary]="true" /><span class="text-label">{{scopeOption.value}}</span>
								<div class="implicitly-selected-icon ficon ficon-AcceptMedium" *ngIf="self.isAllScopeOptionSelectedForScopeVariable(state, scopeVariable.id)"></div>
							</label>
						</ng-container>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div
		class="snapshot-with-options-wrapper"
		*ngIf="isSnapshotsSelectorPresent()"
		[ngClass]="{'no-snapshots-available-for-modifiable-state': state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION && self.scopeSnapshotsInDropdownCount(state) === 0}"
		[ngStyle]="{'z-index': ZIndexForDropdowns}"
	>
		<div class="disabler-overlay" *ngIf="self.isSelectorDisabled(state)"></div>

		<ng-container *ngIf="drawSnapshotToScopeConnectors as connectorInfo">
			<svg class="connectors-from-pre-snapshot-midline-to-snapshot" width="100%" height="100%" [ngStyle]="{
				width: connectorInfo.distanceFromSnapshotToMidlinePx + 'px',
				left: '-' + connectorInfo.distanceFromSnapshotToMidlinePx + 'px'
			}">
				<line
					stroke-linecap="square"
					[attr.x1]="1"
					[attr.x2]="'100%'"
					[attr.y1]="'50%'"
					[attr.y2]="'50%'"
					[attr.stroke-width]="strokeWidthForScopeToSnapshotConnector"
					[attr.stroke]="'#cccccc'" />

				<line
					stroke-linecap="square"
					[attr.x1]="1"
					[attr.x2]="1"
					[attr.y1]="'0%'"
					[attr.y2]="'50%'"
					[attr.stroke-width]="strokeWidthForScopeToSnapshotConnector"
					[attr.stroke]="'#cccccc'"
					*ngIf="connectorInfo.areMoreSnapshotsAbove" />
				<line
					stroke-linecap="square"
					[attr.x1]="1"
					[attr.x2]="1"
					[attr.y1]="'50%'"
					[attr.y2]="'100%'"
					[attr.stroke-width]="strokeWidthForScopeToSnapshotConnector"
					[attr.stroke]="'#cccccc'"
					*ngIf="connectorInfo.areMoreSnapshotsBelow"/>
			</svg>
		</ng-container>
		<div
			class="custom-select"
			[ngClass]="{
				'enabled': self.isScopeVersionSelectableOrCreatable(state),
				'modified': self.isSelectedScopeSnapshotIdDirty(state),
				'unselected': self.selectedScopeSnapshot(state) === undefined
			}"
		>
			<!-- Readonly mode. No snapshots dropdown available. If a snapshot is selected though, it will show the selected snapshot's version, otherwise, the entire snapshot section will be hidden. -->
			<ng-container *ngIf="!self.isScopeVersionSelectableOrCreatable(state) && self.selectedScopeSnapshot(state); let selectedScopeSnapshot">
				<div class="primary-label no-dirty-indicator">
					<div class="label-text">snapshot</div>
				</div>
				<div class="secondary-label">
					<span>{{versionLabel(selectedScopeSnapshot.scopeVersion)}}</span>
				</div>
			</ng-container>

			<!-- Selection mode. If a snapshot is selected, it will show the selected snapshot's version. Otherwise, it will show "Select a snapshot", unless there are no snapshots available for the scope, in which case it will show "No snapshots available". The dropdown will be available if there are snapshots available in the scope. -->
			<ng-container *ngIf="state.staticity !== Staticity.STATIC_SCOPE_WITH_STATIC_VERSION && self.isScopeVersionSelectableOrCreatable(state)">
				<ng-container *ngIf="self.selectedScopeSnapshot(state); let selectedScopeSnapshot">
					<div class="primary-label no-dirty-indicator">
						<div class="label-text">snapshot</div>
					</div>
					<div class="secondary-label">
						<ng-container *ngIf="showDiffs && self.initialScopeSnapshot(state); let initialScopeSnapshot">
							<ng-container *ngIf="initialScopeSnapshot.scopeVersion !== selectedScopeSnapshot.scopeVersion">
								<span class="show-as-older-state">{{versionLabel(initialScopeSnapshot.scopeVersion)}}</span>
								<div class="ficon ficon-SortDown transformation-icon"></div>
							</ng-container>
						</ng-container>
						<span>{{versionLabel(selectedScopeSnapshot.scopeVersion)}}</span>
					</div>
				</ng-container>
				<div *ngIf="!self.selectedScopeSnapshot(state) && self.selectedScopeWithSnapshots(state); let selectedScope">
					<div class="primary-label no-dirty-indicator">
						<ng-container *ngIf="selectedScope.scopeSnapshots.length > 0">
							<div class="label-text">Select a snapshot</div><div class="ficon ficon-ChevronDownMed"></div>
						</ng-container>
						<div class="label-text" *ngIf="selectedScope.scopeSnapshots.length === 0">No snapshots available</div>
					</div>
				</div>

				<!-- Dropdown menu containing all the snapshots available for the scope when hovered over. It is only displayed if you are allowed to select a snapshot according to the staticity and there are snapshots available for the scope. -->
				<ng-container *ngIf="self.selectedScopeWithSnapshots(state); let selectedScope">
					<div class="dropdown-wrapper">
						<div class="dropdown" *ngIf="!hideSnapshotsDropdown && selectedScope.scopeSnapshots.length > 0">
							<ng-container *ngIf="self.selectedScopeSnapshot(state)">
								<div pRipple class="dropdown-option none-option" (click)="handleClickDeselectScopeSnapshotInDropdown()">
									<span class="text-label">None</span>
								</div>
								<div class="divider-line-and-margin"></div>
							</ng-container>
							<div
								pRipple class="dropdown-option"
								[ngClass]="{
									'initially-selected':	scopeSnapshot.id === self.selectedScopeSnapshot(self.initialStateFromState(state))?.id,
									'newly-selected':			scopeSnapshot.id === self.selectedScopeSnapshot(state)?.id
								}"
								(click)="handleClickScopeSnapshotInDropdown(scopeSnapshot)"
								*ngFor="let scopeSnapshot of selectedScope.scopeSnapshots"
							>
								<span class="text-label">{{versionLabel(scopeSnapshot.scopeVersion)}}</span>
							</div>
						</div>
					</div>
				</ng-container>

				<div (click)="enterScopeVersionInputMode()" pRipple class="button gray enter-scope-version-input action" *ngIf="!self.isSelectorDisabled(state) && self.isScopeVersionCreatable(state)">
					<div class="ficon icon-only ficon-Add"></div>
				</div>
			</ng-container>
		</div>
	</div>

	<div *ngIf="self.versionInputtableModificationsOrReturnUndefined(state); let modifications" class="scope-version-input-wrapper">
		<div class="disabler-overlay" *ngIf="self.isSelectorDisabled(state)"></div>
		<input
			pInputText
			pInputFilled
			pAutoFocus
			[autofocus]="true"
			(keydown.Escape)="leaveScopeVersionInputMode()"
			[ngClass]="{'ng-invalid': !modifications.versionInput}" [placeholder]="'Enter scope version (e.g. \'1\')'"
			type="text"
			class="scope-version-input p-inputtext-sm"
			[(ngModel)]="modifications.versionInput"
		/>
		<div (click)="leaveScopeVersionInputMode()" pRipple class="button gray cancel action" *ngIf="self.selectedScope(state)"><div class="ficon icon-only ficon-Cancel"></div></div>
	</div>
</div>
