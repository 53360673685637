import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DescendantScopeDifference, SavedSsInheritance, ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment, ScopeVariableFieldsForScopeSnapshotSelectorFragment, ValidatedSsInheritanceForDescendantSs } from 'src/generated/graphql';
import _ from 'lodash';
import { ScopeSnapshotSelectorComponent, Staticity } from '../scope-snapshot-selector/scope-snapshot-selector.component';
import { CommonService } from 'src/app/app-common/common.service';
import { ViewOrModifyOrDiscardSsInheritancesAction } from 'src/app/scope/scopes-page/scopes-page.component';

@Component({
  selector: 'app-scope-snapshot-modifiable-descendant-inheritances[target-scope-snapshot][descendant-scope-differences][scope-variables][grid-column-start-of-scope-snapshot-selector]',
  templateUrl: './scope-snapshot-modifiable-descendant-inheritances.component.html',
  styleUrl: './scope-snapshot-modifiable-descendant-inheritances.component.scss'
})
export class ScopeSnapshotModifiableDescendantInheritancesComponent {
	_ = _;
	versionLabel = CommonService.friendlyVersionLabel;
	ssStaticity = Staticity;
	component = ScopeSnapshotModifiableDescendantInheritancesComponent;
	@Input('target-scope-snapshot') targetScopeSnapshot!: ScopeSnapshotFieldsForStaticScopeSnapshotSelectorFragment;
	@Input('descendant-scope-differences') descendantScopeDifferences!: DescendantScopeDifference[];
	@Input('scope-variables') scopeVariables!: ScopeVariableFieldsForScopeSnapshotSelectorFragment[];
	@Input('grid-column-start-of-scope-snapshot-selector') gridColumnStartOfScopeSnapshotSelector!: string;
	@Output('view-or-modify-or-discard-ss-inheritances') viewOrModifyOrDiscardSsInheritances = new EventEmitter<ViewOrModifyOrDiscardSsInheritancesAction>();

	// inViewportOptions = {
	// 	threshold: [0],
	// 	rootMargin: '-120px 0px -140px 0px' // top right bottom left. A row is considered in viewport if its top is 120px from the top of the viewport, and its bottom is 140px from the bottom of the viewport.
	// };

	constructor() {}

	static primitiveToStaticScopeSnapshot = ScopeSnapshotSelectorComponent.primitiveToStaticScopeSnapshot;

	// onRowInViewport(inViewportAction: InViewportAction, row: HTMLDivElement): void {
	// 	const inViewportClass = 'in-viewport';
	// 	const notInviewportClass = 'not-in-viewport';

	// 	this.renderer.addClass(row, inViewportAction.visible ? inViewportClass : notInviewportClass);
	// 	this.renderer.removeClass(row, inViewportAction.visible ? notInviewportClass : inViewportClass);
	// }

	isFirstInheritanceRow(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const firstNonEmptyScope = this.descendantScopeDifferences.find(dsd => dsd.descendantScopeSnapshots.length > 0);
		return firstNonEmptyScope?.descendantScopeSnapshots[0]?.id === descendantScopeSnapshot.id;
	}

	isLastInheritanceRow(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const reversed = [...this.descendantScopeDifferences].reverse();
		const lastNonEmptyScope = reversed.find(dsd => dsd.descendantScopeSnapshots.length > 0);
		if (lastNonEmptyScope !== undefined) {
			return lastNonEmptyScope.descendantScopeSnapshots[lastNonEmptyScope.descendantScopeSnapshots.length - 1]?.id === descendantScopeSnapshot.id;
		}
		return false;
	}

	isInheritanceRowChanged(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
		const secondValidatedInheritance = descendantScopeSnapshot.validatedInheritances[1];
		// This means there's conflicting inheritances
		if (secondValidatedInheritance !== undefined) {
			return true;
		}

		if (firstValidatedInheritance === undefined) {
			return descendantScopeSnapshot.savedInheritance !== null;
		}
		else {
			return	descendantScopeSnapshot.savedInheritance === null ||
							firstValidatedInheritance.inheritedScopeSnapshotId !== descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshotId ||
							firstValidatedInheritance.isDirectInheritance !== descendantScopeSnapshot.savedInheritance.isDirectInheritance ||
							firstValidatedInheritance?.ordinalWithinDirectInheritances !== descendantScopeSnapshot.savedInheritance?.ordinalWithinDirectInheritances;
		}
	}

	isInheritanceRowNonDescendant(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): boolean {
		if (initialOrCurrent === 'initial') {
			return descendantScopeSnapshot.savedInheritance === null;
		}
		else {
			return descendantScopeSnapshot.validatedInheritances.length === 0;
		}
	}

	inheritanceIfInheritingFromJustAlternateSnapshotInScope(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): SavedSsInheritance | ValidatedSsInheritanceForDescendantSs | undefined {
		const inheritance = this.inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot, initialOrCurrent);
		if (inheritance !== undefined && inheritance.inheritedScopeSnapshot.id !== this.targetScopeSnapshot.id) {
			return inheritance;
		}
	}

	inheritanceIfInheritingFromJustTargetScopeSnapshot(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): SavedSsInheritance | ValidatedSsInheritanceForDescendantSs | undefined {
		const inheritance = this.inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot, initialOrCurrent);
		if (inheritance !== undefined && inheritance.inheritedScopeSnapshot.id === this.targetScopeSnapshot.id) {
			return inheritance;
		}
	}

	inheritanceIfInheritingFromTargetScopeWithoutConflicts(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): SavedSsInheritance | ValidatedSsInheritanceForDescendantSs | undefined {
		if (initialOrCurrent === 'initial') {
			if (descendantScopeSnapshot.savedInheritance?.inheritedScopeSnapshot.scopeId === this.targetScopeSnapshot.scopeId) {
				return descendantScopeSnapshot.savedInheritance;
			}
		}
		else {
			const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
			if (descendantScopeSnapshot.validatedInheritances.length === 1 && firstValidatedInheritance?.inheritedScopeSnapshot.scopeId === this.targetScopeSnapshot.scopeId) {
				return firstValidatedInheritance;
			}
		}
	}

	isInheritanceRowDirectDescendant(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): boolean {
		if (initialOrCurrent === 'initial') {
			return descendantScopeSnapshot.savedInheritance?.isDirectInheritance === true;
		}
		else {
			const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
			return firstValidatedInheritance?.isDirectInheritance === true && descendantScopeSnapshot.validatedInheritances.length === 1;
		}
	}

	isInheritanceRowIndirectDescendant(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], initialOrCurrent: 'initial' | 'current'): boolean {
		if (initialOrCurrent === 'initial') {
			return descendantScopeSnapshot.savedInheritance?.isDirectInheritance === false;
		}
		else {
			const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
			return firstValidatedInheritance?.isDirectInheritance === false && descendantScopeSnapshot.validatedInheritances.length === 1;
		}
	}

	isInheritanceRowUsingConflictingInheritances(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): boolean {
		return descendantScopeSnapshot.validatedInheritances.length > 1;
	}

	pTooltipForCurrentRowState(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): string {
		const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
		const secondValidatedInheritance = descendantScopeSnapshot.validatedInheritances[1];
		if (firstValidatedInheritance && secondValidatedInheritance) {
			return `This scope snapshot has conflicting inheritances because it inherits ${descendantScopeSnapshot.validatedInheritances.length} snapshot${descendantScopeSnapshot.validatedInheritances.length !== 1 ? 's' : ''} of the target scope: ${descendantScopeSnapshot.validatedInheritances.map(inheritance => this.versionLabel(inheritance.inheritedScopeSnapshot.scopeVersion)).join(', ')}.`;
		}
		else if (firstValidatedInheritance) {
			const indicatorForWhichSnapshotOfTargetScope = firstValidatedInheritance.inheritedScopeSnapshot.id === this.targetScopeSnapshot.id ? 'the target scope snapshot' : (this.versionLabel(firstValidatedInheritance.inheritedScopeSnapshot.scopeVersion) + ' of the target scope');

			const isFutureTense = this.isInheritanceRowChanged(descendantScopeSnapshot);

			if (firstValidatedInheritance.isDirectInheritance) {
				return `This scope snapshot ${isFutureTense ? 'will directly inherit' : 'directly inherits'} ${indicatorForWhichSnapshotOfTargetScope}.

If desired, use the snapshot dropdown to either inherit a different snapshot of the target scope, or choose not to inherit from the target scope at all.`;
			}
			else {
				return `This scope snapshot ${isFutureTense ? 'will indirectly inherit' : 'indirectly inherits'} ${indicatorForWhichSnapshotOfTargetScope}.`;
			}
		}
		else {
			const isFutureTense = this.isInheritanceRowChanged(descendantScopeSnapshot);
			return `This scope snapshot ${isFutureTense ? 'will no longer inherit' : 'does not inherit'} from the target scope.

If desired, you can select a snapshot of the target scope to inherit from.`;
		}
	}
	viewInheritancesOf(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): void {
		this.viewOrModifyOrDiscardSsInheritances.emit({
			ssInheritanceActionsForEachTarget: new Map([[descendantScopeSnapshot.id, {
				actionType: 'view',
				clearExistingModificationsIfPresent: false
			}]]),
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			scrollToTargetScopeSnapshot: descendantScopeSnapshot.id
		});
	}

	inheritanceDropdownOptionsForRow(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number]): {id: number, scopeVersion: string}[] {
		const idToDetailsMap = new Map<number, {scopeVersion: string}>();

		if (descendantScopeSnapshot.savedInheritance !== null) {
			idToDetailsMap.set(descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshotId, {
				scopeVersion: descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshot.scopeVersion
			});
		}

		// descendantScopeSnapshot.validatedInheritances.forEach(inheritance => {
		// 	idToDetailsMap.set(inheritance.inheritedScopeSnapshotId, {
		// 		scopeVersion: inheritance.inheritedScopeSnapshot.scopeVersion
		// 	});
		// });

		// result.push({
		// 	id: descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshotId,
		// 		scopeVersion: descendantScopeSnapshot.savedInheritance.inheritedScopeSnapshot.scopeVersion
		// 	});
		// }

		descendantScopeSnapshot.validatedInheritances.forEach(inheritance => {
			idToDetailsMap.set(inheritance.inheritedScopeSnapshotId, {
				scopeVersion: inheritance.inheritedScopeSnapshot.scopeVersion
			});
		});

		idToDetailsMap.set(this.targetScopeSnapshot.id, {
			scopeVersion: this.targetScopeSnapshot.scopeVersion
		});


		return Array.from(idToDetailsMap.entries()).map(([id, details]) => ({
			id,
			scopeVersion: details.scopeVersion
		}));
	}

	setInheritanceOfDescendantToSnapshotOfTargetScope(descendantScopeSnapshot: DescendantScopeDifference['descendantScopeSnapshots'][number], snapshotIdWithinTargetScope: number | null): void {
		const areThereConflictingInheritances = descendantScopeSnapshot.validatedInheritances[1] !== undefined;

		if (areThereConflictingInheritances) {
			throw new Error('Cannot set inheritance for a row that represents conflicting inheritances');
		}

		let newValidatedDirectParentIds = descendantScopeSnapshot.validatedDirectParentIds.slice();

		const firstValidatedInheritance = descendantScopeSnapshot.validatedInheritances[0];
		if (!firstValidatedInheritance) {
			if (snapshotIdWithinTargetScope === null) {
				return; // Nothing to do because we weren't inheriting from the target scope in the first place and so there's nothing to remove
			}
			else {
				newValidatedDirectParentIds.push(snapshotIdWithinTargetScope);
			}
		}
		else if (firstValidatedInheritance && firstValidatedInheritance.isDirectInheritance) {
			const indexOfDirectParentIdToReplace = descendantScopeSnapshot.validatedDirectParentIds.indexOf(firstValidatedInheritance.inheritedScopeSnapshotId);
			if (indexOfDirectParentIdToReplace === -1) {
				throw new Error('Cannot find direct parent id to replace');
			}
			else {
				if (snapshotIdWithinTargetScope === null) {
					newValidatedDirectParentIds.splice(indexOfDirectParentIdToReplace, 1);
				}
				else {
					newValidatedDirectParentIds[indexOfDirectParentIdToReplace] = snapshotIdWithinTargetScope;
				}
			}
		}
		else {
			throw new Error('Cannot set inheritance for a row that represents an indirect inheritance');
		}

		this.viewOrModifyOrDiscardSsInheritances.emit({
			ssInheritanceActionsForEachTarget: new Map([[descendantScopeSnapshot.id, {
				actionType: 'modify',
				directParentScopeSnapshotIds: newValidatedDirectParentIds,
				mergeStrategy: 'replace-entire-parent-list'
			}]]),
			overallMergeStrategy: 'only-perform-actions-on-provided-targets',
			scrollToTargetScopeSnapshot: null
		});
	}
}
