<div class="scopes-and-snapshots-container">
	<ng-container *ngIf="scopesPageData">
		<div class="section-title">{{scopesPageData.scopeData.length}} Scope{{scopesPageData.scopeData.length !== 1 ? 's' : ''}} and {{totalNumberOfScopeSnapshots()}} Snapshot{{totalNumberOfScopeSnapshots() !== 1 ? 's' : ''}}</div>
		<div class="grid-based-table listing-for-scope-snapshots" [ngStyle]='{
			"grid-template-columns": "[start] repeat(" + (scopesPageData.scopeVariables.length) + ", [start-of-scope-variable] 1fr) [start-of-snapshot-deployments-notice] 70px " + (!isModifyingScopeDefinitions() ? "[start-of-scope-version-dropdown] minmax(190px, auto)" : "") + " [start-of-state-and-actions] minmax(470px, auto) [end]"
		}'>
			<div class="disabler-overlay" *ngIf="isLoading"></div>
			<div
				class="headings-background non-sticky">
			</div>

			<div
				class="scope-options-dropdown-heading heading non-sticky"
				[ngStyle]="{
					'grid-column-start': 'start-of-scope-variable ' + (scopeVariableIndex + 1),
				}"
				*ngFor="let scopeVariable of scopesPageData.scopeVariables; let scopeVariableIndex=index"
			>
				{{scopeVariable.name}}
			</div>

			<div
				class="deployments-heading heading non-sticky"
				*ngIf="!isModifyingScopeDefinitions()">
				Deployments and Snapshots
			</div>

			<div
				class="state-and-actions-heading heading non-sticky">
				Actions
			</div>

			<!-- Scope row and details when the page is in a state where you can modify scope definitions. -->
			<ng-container *ngIf="scopesPageData.state === PageState.MODIFIABLE_SCOPE_DEFINITIONS">
				<div
					class="row modifiable-scope-snapshot-minimalistic"
					*ngFor="let scopeDatum of scopesPageData.scopeData; let index = index">
					<app-scope-snapshot-selector
						[state]="scopeDatum.selectorState"
						[styles-for-spanning-across-parent-grid]="{
							'first-dropdown': {
								'grid-column-start': gridColumnStartOfScopeSnapshotSelector
							}
						}"
						[show-diffs]="true"
						[actioner]="scopeDatum.actioner"
						[behave-as-scope-selector-only]="true"
						(state-update)="handleScopeSnapshotSelectorStateChange($event)"
						>
					></app-scope-snapshot-selector>
					<div class="state-and-actions">
						<div class="state">
							<ng-container *ngIf="ScopeSnapshotSelectorComponent.presentableErrorsPreventingScopeDefinitionUpdate(scopeDatum.selectorState); let errors;">
								<div class="error-summary" (click)="toggleScopeDefinitionErrorExpansion(scopeDatum)" *ngFor="let error of errors">
									<div class="ficon ficon-Important"></div>

									<div class="action-link danger" *ngIf="error.isExpandable">
										{{ error.summary }}
										<div
											class="ficon"
											[ngClass]="{
												'ficon-ChevronDownMed': !scopeDatum.isErrorExpanded,
												'ficon-ChevronUpMed': scopeDatum.isErrorExpanded
											}"
											*ngIf="error.isExpandable"
										>
										</div>
									</div>
									<div class="static-error-text" *ngIf="!error.isExpandable">{{ error.summary }}</div>

								</div>
							</ng-container>
						</div>
						<ng-container *ngIf="ScopeSnapshotSelectorComponent.whitelistedActionBases(scopeDatum.selectorState); let actionMap">
							<div
								pRipple class="button"
								[ngClass]="{'gray': !actionBase.isPrimary}"
								(click)="scopeDatum.actioner.next(actionBase)"
								*ngIf="actionMap[actionTypes.UPDATE_SCOPE_DEFINITION]; let actionBase">
								<div class="ficon ficon-AcceptMedium"></div>{{actionBase.defaultButtonLabel}}
							</div>
							<div
								class="action-link"
								(click)="scopeDatum.actioner.next(actionBase)"
								*ngIf="ScopeSnapshotSelectorComponent.isScopeDefinitionDirty(scopeDatum.selectorState) && actionMap[actionTypes.RESET_TO_INITIAL_STATE]; let actionBase">
								<div class="ficon icon ficon-Cancel"></div>
								{{actionBase.defaultButtonLabel}}
							</div>
						</ng-container>

					</div>
					<ng-container *ngIf="ScopeSnapshotSelectorComponent.presentableErrorsPreventingScopeDefinitionUpdate(scopeDatum.selectorState); let errors;">
						<ng-container *ngFor="let error of errors">
							<div class="error-details" *ngIf="error.code === ErrorPreventingActionCode.INCOMPATIBLE_INHERITANCES && scopeDatum.isErrorExpanded">
								<div class="subsection-smallcaps-title">One or more scope snapshots are incompatible with this new scope definition</div>
								<p>The current inheritances within one or more scope snapshots are incompatible with this new scope definition. In order for an inheritance to remain compatible, the scope options targeted by the child scope must remain a subset of the scope options targeted by the parent scope. In other words, every inheritance must have its child scope remain narrower than its parent scope. To fix this, consider one or more of the following:</p>
								<ol>
									<li>If you're making the scope narrower (i.e., if you're targeting fewer scope options), consider narrowing the child scopes first and then work your way up to narrowing the parent scopes.</li>
									<li>If you're making the scope broader (i.e., if you're targeting more scope options), consider broadening the parent scopes first and then work your way down to broadening the child scopes.</li>
									<li>Modify the snapshots of the child scopes so that they no longer inherit from this scope. Only do this if such scope snapshots shouldn't actually inherit from this scope.</li>
									<li>Modify the snapshots of this scope so that they no longer inherit from the incompatible parent scopes. Only do this if such scope snapshots shouldn't actually inherit from those parent scopes.</li>
									<li>Select a different set of scope options for this scope altogether.</li>
								</ol>

								<ng-template
									#incompatibleInheritancesTemplate
									let-typeUnsafeIncompatibleInheritances="incompatibleInheritances"
									let-scopesCount="scopesCount"
									let-isShowingIncompatibleParents="isShowingIncompatibleParents"
								>
									<ng-container *ngIf="typedIncompatibleInheritances(typeUnsafeIncompatibleInheritances); let incompatibleInheritances">
										<div class="incompatible-scope-snapshots-section">
											<div class="subsection-smallcaps-title">{{incompatibleInheritances.length}} Incompatible {{isShowingIncompatibleParents ? 'parent' : 'child'}} snapshot{{incompatibleInheritances.length !== 1 ? 's' : ''}} {{scopesCount > 1 ? 'across ' + scopesCount + ' scopes' : 'in ' + scopesCount + ' scope'}}</div>
											<p *ngIf="incompatibleInheritances.length > 1">

												Here are the {{incompatibleInheritances.length}} incompatible {{isShowingIncompatibleParents ? 'parent' : 'child'}} scope snapshots for your reference. You may want to {{isShowingIncompatibleParents ? 'broaden' : 'narrow'}} their scopes on this page{{isShowingIncompatibleParents ? ', or you may want to stop inheriting from these scopes': ', or you may want to stop these child scope snapshots from inheriting this scope' }}.

											</p>
											<p *ngIf="incompatibleInheritances.length === 1">

												Here is the incompatible {{isShowingIncompatibleParents ? 'parent' : 'child'}} scope snapshot for your reference. You may want to {{isShowingIncompatibleParents ? 'broaden' : 'narrow'}} its scope on this page{{isShowingIncompatibleParents ? ', or you may want to stop inheriting from it': ', or you may want to stop this child scope snapshot from inheriting this scope' }}.
											</p>
											<div
												class="scope-snapshots-list"
												[ngStyle]='{
													"grid-template-columns": "[start] repeat(" + (scopesPageData.scopeVariables.length) + ", [start-of-scope-variable] min-content) [start-of-scope-version-dropdown] minmax(160px, min-content) [start-of-actions] auto [end]"
												}'
											>
												<div class="inner-headings">
													<div
														class="inner-heading"
														*ngFor="let scopeVariable of scopesPageData.scopeVariables; let scopeVariableIndex=index"
													>
														{{scopeVariable.name}}
													</div>
													<div class="inner-heading">Snapshot</div>
													<div class="inner-heading actions-heading">Actions</div>
												</div>
												<ng-container *ngFor="let incompatibleInheritance of incompatibleInheritances; let index = index">
													<ng-container *ngIf="extractStaticScopeSnapshotFromValidatableInheritance(incompatibleInheritances, index, isShowingIncompatibleParents); let currentScopeSnapshot">
														<app-scope-snapshot-selector
															[state]="{
																initialScopeSnapshot: currentScopeSnapshot,
																actionsWhitelist: [actionTypes.GO_TO_SNAPSHOT_IN_SAME_SCOPE],
																staticity: Staticity.STATIC_SCOPE_WITH_STATIC_VERSION,
																scopeVariables: scopesPageData.scopeVariables
															}"
															[styles-for-spanning-across-parent-grid]="{
																'first-dropdown': {
																	'grid-column-start': gridColumnStartOfScopeSnapshotSelector
																}
															}"
															[ngClass]="{
																'is-first-row': index === 0,
																'not-first-snapshot-within-scope': extractStaticScopeSnapshotFromValidatableInheritance(incompatibleInheritances, index - 1, isShowingIncompatibleParents)?.scopeId === currentScopeSnapshot.scopeId
															}"
														>
														</app-scope-snapshot-selector>
														<div class="actions-wrapper">
															<div class="actions">
																<a
																	[routerLink]="['/scope-snapshot/' + (isShowingIncompatibleParents ? incompatibleInheritance.directlyInheritedScopeSnapshotId : incompatibleInheritance.scopeSnapshotId)]"
																	class="action-link"
																	target="_blank">
																	Go to {{versionLabel(currentScopeSnapshot.scopeVersion)}}<div class="ficon icon-on-right ficon-OpenInNewTab"></div>
																</a>
																<a
																[routerLink]="['/scopes-and-snapshots']"
																[queryParams]="self.queryParamsForOpeningInheritancesOnScopesAndSnapshotsPage(currentScopeSnapshot.id)"
																class="action-link"
																target="_blank">
																	<div class="ficon ficon-SplitObject"></div>
																	Modify Inheritances of {{versionLabel(currentScopeSnapshot.scopeVersion)}}<div class="ficon icon-on-right ficon-OpenInNewTab"></div>
																</a>
															</div>
														</div>
													</ng-container>
												</ng-container>
											</div>
										</div>
									</ng-container>
								</ng-template>

								<ng-container *ngIf="error.incompatibleParentSnapshotInheritances.length > 0">
									<ng-container *ngTemplateOutlet="incompatibleInheritancesTemplate; context: {incompatibleInheritances: error.incompatibleParentSnapshotInheritances, isShowingIncompatibleParents: true, scopesCount: error.incompatibleParentScopeInheritances.length}"></ng-container>
								</ng-container>

								<ng-container *ngIf="error.incompatibleChildSnapshotInheritances.length > 0">
									<ng-container *ngTemplateOutlet="incompatibleInheritancesTemplate; context: {incompatibleInheritances: error.incompatibleChildSnapshotInheritances, isShowingIncompatibleParents: false, scopesCount: error.incompatibleChildScopeInheritances.length}"></ng-container>
								</ng-container>

							</div>
						</ng-container>
					</ng-container>
					<div class="row-spacer-and-hoverable"></div>
				</div>
			</ng-container>

			<!-- Scope row and details when the page is in it's default state - where you can modify scope snapshot inheritances and also go to individual scope snapshots. -->
			<ng-container *ngIf="scopesPageData.state === PageState.MODIFIABLE_SS_INHERITANCES">
				<div
					class="row static-scope-snapshot"
					*ngFor="let scopeDatum of scopesPageData.scopeData; let index = index">
					<app-scope-snapshot-selector
						[state]="scopeDatum.selectorState"
						[styles-for-spanning-across-parent-grid]="{
							'first-dropdown': {
								'grid-column-start': gridColumnStartOfScopeSnapshotSelector
							}
						}"
						[actioner]="scopeDatum.actioner"
						(state-update)="handleScopeSnapshotSelectorStateChange($event)"
						>
					></app-scope-snapshot-selector>
					<div class="snapshot-deployments-notice" [ngStyle]="stylesForSnapshotDeploymentsNotice(scopeDatum)" *ngIf="scopeDatum.scope.deployments.length > 0">
						<div class="related-objects-notice has-tooltip-indicator">
							<div class="ficon ficon-Rocket"></div><div class="notice-text">{{scopeDatum.scope.deployments.length}}</div>
							<div class="related-objects-popup">
								<div class="popup-title">{{scopeDatum.scope.deployments.length}} deployment{{scopeDatum.scope.deployments.length !== 1 ? 's' : ''}} using {{numUniqueScopeVersionsInDeployments(scopeDatum.scope.deployments)}} snapshot{{numUniqueScopeVersionsInDeployments(scopeDatum.scope.deployments) !== 1 ? 's' : ''}}</div>
								<div class="object-rows">
									<div class="object-row" *ngFor="let deployment of scopeDatum.scope.deployments">
										<div class="object-row-key">{{deployment.key}}</div>
										<div class="object-row-value">
											{{versionLabel(deployment.scopeVersion)}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="state-and-actions">
						<div class="state"></div>
						<ng-container *ngIf="ScopeSnapshotSelectorComponent.whitelistedActionBases(scopeDatum.selectorState); let actionMap">
							<ng-container *ngIf="actionMap[actionTypes.VIEW_INHERITANCES_OF_NEWLY_SELECTED_SCOPE_SNAPSHOT]; let actionBase">
								<div
									class="action-link"
									(click)="viewInheritancesOfScopeSnapshotId(actionBase.selectedScopeSnapshot.id)"
									*ngIf="!scopesPageData.inputsForValidatingInheritanceModifications.hasOwnProperty(actionBase.selectedScopeSnapshot.id)">
									<div class="ficon icon ficon-SplitObject"></div>
									Modify Inheritances of {{versionLabel(actionBase.selectedScopeSnapshot.scopeVersion)}}
									<div
										class="ficon ficon-ChevronDownMed"
									></div>
								</div>
							</ng-container>
							<a
								[routerLink]="['/scope-snapshot/' + actionBase.destinationScopeSnapshot.id]"
								pRipple class="button"
								target="_blank"
								[ngClass]="{'gray': !actionBase.isPrimary}"
								*ngIf="actionMap[actionTypes.GO_TO_SNAPSHOT_IN_SAME_SCOPE]; let actionBase">
								{{actionBase.defaultButtonLabel}}<div class="ficon icon-on-right ficon-OpenInNewTab"></div>
							</a>
							<div pRipple class="button red" (click)="deleteScope(scopeDatum.scope)" *ngIf="!areAnySsInheritancesBeingEdited() && scopeDatum.scope.scopeSnapshots.length === 0">
								<div class="ficon ficon-Delete"></div>
								Delete Empty Scope
							</div>
						</ng-container>

					</div>
					<ng-container *ngIf="snapshotInheritanceValidationsForScope(scopeDatum.scope.id); let validationsByTargetSnapshots">
						<ng-container *ngFor="let validations of validationsByTargetSnapshots; let index = index">
							<app-scope-snapshot-inheritances
								[scope-snapshot]="self.primitiveToStaticScopeSnapshot(validations.targetScopeSnapshot, scopeDatum.scope)"
								[static-or-validated-inheritances]="validations"
								[scope-variables]="scopesPageData.scopeVariables"
								(view-or-modify-or-discard-ss-inheritances)="viewOrModifyOrDiscardSsInheritances($event)"
								[appScopeSnapshotId]="validations.targetScopeSnapshot.id"
								#inheritancesForSs
								>
							</app-scope-snapshot-inheritances>
						</ng-container>
					</ng-container>
					<div class="row-spacer-and-hoverable"></div>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>

<div class="actions-bar" *ngIf="scopesPageData">
	<div class="disabler-overlay" *ngIf="isLoading"></div>
	<div class="notices-section">
		<ng-container *ngIf="scopesPageData.state === PageState.MODIFIABLE_SS_INHERITANCES && errorNoticesThatPreventSavingSsInheritances().length > 0">
			<div class="ficon ficon-Important"></div>
			<div class="ss-inheritance-modifications-notices">
				<div class="notices-heading">Fix these errors in order to proceed:</div>
				<div class="notices">
					<div class="notice" *ngFor="let errorNotice of errorNoticesThatPreventSavingSsInheritances()">
						{{errorNotice}}
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="actions">
		<ng-container *ngIf="scopesPageData.state === PageState.MODIFIABLE_SCOPE_DEFINITIONS">
			<div class="action-link" (click)="initPageForModifyingSsInheritances(requireScopes(), scopesPageData.scopeVariables)">
				<div class="ficon ficon-Cancel"></div>
				<span>Close Scope Definition Modifications</span>
			</div>
		</ng-container>
		<ng-container *ngIf="scopesPageData.state === PageState.MODIFIABLE_SS_INHERITANCES">
			<div class="action-link" (click)="discardAllSsInheritanceModifications();" *ngIf="areAnySsInheritancesBeingEdited()">
				<div class="ficon ficon-Cancel"></div>
				<span>Discard All Inheritances</span>
			</div>
			<!-- TODO Only show this if there are no errors. And also customize the button text to indicate how many base scope snapshots are being modified -->
			<div pRipple class="button" (click)="saveSsInheritanceModifications()" *ngIf="areSsInheritancesSaveable()">
				<div class="ficon ficon-AcceptMedium"></div>
				<span>Save All Inheritances</span>
			</div>
			<div class="action-link" (click)="initPageForModifyingScopeDefinitions(requireScopes(), scopesPageData.scopeVariables)" *ngIf="!areAnySsInheritancesBeingEdited()">
				<div class="ficon ficon-BullseyeTargetEdit"></div>
				<span>Modify Scope Definitions</span>
			</div>

		</ng-container>
		<!-- <div class="action-link" (click)="initPageForModifyingSsInheritances(requireScopes(), scopesPageData.scopeVariables)" *ngIf="isModifyingScopeDefinitions()">
			<div class="ficon ficon-Cancel"></div>
			<span>Close Scope Definition Modifications</span>
		</div> -->
	</div>
</div>
